import { PolygonLayer,IconLayer } from "@deck.gl/layers";
import { WebMercatorViewport } from '@deck.gl/core';
import { DeckGL } from "@deck.gl/react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Backdrop, Card,CardActions, CircularProgress, Grid, Container,FormControl, TextField, InputLabel, MenuItem, Select, Paper, Button, Dialog, DialogContent, DialogTitle, Slide} from "@mui/material";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { APIProvider, AdvancedMarker, Map, Marker, limitTiltRange, useMap, Pin } from "@vis.gl/react-google-maps";
import MKBox from "components/MKBox";
import BaseLayout from "layouts/sections/components/BaseLayout";
import React,{ useEffect, useState, useRef  } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { plantationActions } from "sagas/plantationSlice";
import {regionActions} from "sagas/regionSlice";
import {cooperativeActions} from "sagas/cooperativeSlice";
import { ScatterplotLayer } from '@deck.gl/layers';
import IconClusterLayer from './IconClusterLayer';
import atlas from './location-icon-atlas.png';
import mapping from './location-icon-mapping.json';
import Checkbox from '@mui/material/Checkbox';
import { pink } from '@mui/material/colors';
import MKButton from "components/MKButton";
import { Tabs, Tab, Box } from '@mui/material';
import CoopResults from "./CoopResults";

const ICON_MAPPING = {
  marker: {x: 0, y: 0, width: 675, height: 630,}
};
const Geolocalisation = () => {

    const { plantations, isFetching, error } = useSelector(state => state.plantation);
    const [coop, setCoop] = useState(null);
    const [searchCode,setSearchCode] =useState('');
    const [selectedRegion, setSelectedRegion] = useState('0');
    const regions = useSelector(state => state.region);
    const {token} = useSelector(state=>state.user);
    const [geoLib, setGeoLib] = useState(null);
    const [isLoading,setIsLoading]=useState(false);
    const[hover, setHover] = useState(0);
    const [pop, setPop]=useState({top:0,left:0,point_count:0,points:[],areadata:null});
    const [searchPop, setSearchPop] = useState(false);
    const [clustered, setClustered]=useState(true);
    const dispatch = useDispatch();
    const plantationMarkers = [];
    const dataArea = [];
    const { t } = useTranslation('translation');
   

    const handleDownload = () => {
        let payload = {
            token:token,
            onSuccess: (response) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'files.zip'); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            // onError:() => {
            //     alertDispatch("error","Une erreur s'est produite lors du téléchargement du fichier zip.");
 
            // }
        };
        dispatch(plantationActions.downloadGPX(payload));
    };

        const handleDownloadKml = () => {
        let payload = {
            token:token,
            onSuccess: (response) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'files.zip'); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            // onError:() => {
            //     alertDispatch("error","Une erreur s'est produite lors du téléchargement du fichier zip.");
 
            // }
        };
        dispatch(plantationActions.downloadKML(payload));
    };

    
    const handleClose=()=>{
        setHover(0);
    }

    useEffect(() => {
        const getGeolibrary = async () => {
            const { spherical } = await google.maps.importLibrary("geometry");
            return spherical;
        }

        getGeolibrary().then(spherical => setGeoLib(spherical)).catch(console.error);

        dispatch(regionActions.fetchContour());
        dispatch(plantationActions.fetchPlantations());
        

    }, []);

    function calculateCentroid(points) {
        let lng = 0;
        let lat = 0;
        if (points) {
            points.map(point => {
                lng = lng + point.longitude;
                lat = lat + point.latitude;
            });
            lng = lng / points.length;
            lat = lat / points.length;
        }
        return ({
            lat: lat,
            lng: lng
        });
    }

    let key = 0;
    let plantationsFiltered =plantations
    if(coop!=null){
        const codes = getAllCodePlantation(coop?.sections);
        plantationsFiltered = plantations.filter(item => codes.includes(item.codePlantation));
    }

    if(searchCode!==''){
        plantationsFiltered=plantationsFiltered.filter(item=> item.codePlantation.includes(searchCode));
    }

    plantationsFiltered.map(plantation => {
        if (plantation.contour) {
            let parsedContour = JSON.parse(plantation.contour);
            
                plantationMarkers.push({
                key: key,
                name: plantation.codePlantation,
                position: calculateCentroid(parsedContour)
            });

            
            

            if(parsedContour.length>3){

                let newContour = [];
            let areaCalculatorArray = [];
            parsedContour.map(point => {
                let newPoint = [point.longitude, point.latitude];
                let areaPoint = {

                    lat: point.latitude,
                    lng: point.longitude
                };
                areaCalculatorArray.push(areaPoint);
                newContour.push(newPoint);
            });

            let newArea = {
                contour: newContour,
                name: plantation.codePlantation,
                area: 0,
                production: plantation.typePlante
            };
            if (geoLib) {
                
                newArea.area = geoLib.computeArea(areaCalculatorArray);
            }
            else {
                console.log('cannot calculate rn ');
            }

            dataArea.push(newArea);

            }

           /* let newContour = [];
            let areaCalculatorArray = [];
            parsedContour.map(point => {
                let newPoint = [point.longitude, point.latitude];
                let areaPoint = {

                    lat: point.latitude,
                    lng: point.longitude
                };
                areaCalculatorArray.push(areaPoint);
                newContour.push(newPoint);
            });

            let newArea = {
                contour: newContour,
                name: plantation.codePlantation,
                area: 0,
                production: plantation.typePlante,
                cooperative: "Cooperative regionale Kangarassou plantations"
            };
            if (geoLib) {
                
                newArea.area = geoLib.computeArea(areaCalculatorArray);
            }
            else {
                console.log('cannot calculate rn ');
            }

            dataArea.push(newArea);*/
        }
        key = key + 1;
    });


    let regionAreas=[];


    regions.regions.map(
        region =>{
            let parsedContour=JSON.parse(region?.contour);
            let fixedContour=[];
            
            
            let regionObject = {
                    id:region.id,
                    name:region.name,
                    contour:null
                }
            
            parsedContour.map(point => {
                let newPoint = [point.longitude, point.latitude];
                
                let areaPoint = {

                    lat: point.latitude,
                    lng: point.longitude
                };
                fixedContour.push(newPoint);
            });
            regionObject.contour = fixedContour;
            regionAreas.push(regionObject);
            
        }
    );
   
    
  
   let ViewStates=[];
   

   regionAreas.map((area)=>{

          const lats = area.contour.map(point => point[0]);
          const lons = area.contour.map(point => point[1]);
          const minLat = Math.min(...lats);
          const maxLat = Math.max(...lats);
          const minLon = Math.min(...lons);
          const maxLon = Math.max(...lons);

          const bounds = [[minLat, minLon], [maxLat, maxLon]];

           const viewport = new WebMercatorViewport({
                width: window.innerWidth,
                height: window.innerHeight
            });

          const {longitude, latitude, zoom} = viewport.fitBounds(bounds, {
                padding: 20 // Optional: Add some padding around the bounds
            });

           

           let viewState = 
           {
                view:{
                     longitude: longitude,
                     latitude:  latitude,
                     zoom:zoom,
                     pitch:0,
                     bearing:0
                },

                id:area.id
                
            };
           ViewStates.push(viewState);
           });


    

 

   
    const INITIAL_VIEW_STATE = {
        longitude: -5.21054,
        latitude: 6.82448,
        zoom: 7,
        pitch: 0,
        bearing: 0
    };

    const position = { lat: 6.82448, lng: -5.21054 };

    const layer_2 = new PolygonLayer(
        {
            id: 'PolygonLayerForRegions',
            data: regionAreas,
            extruded: true,
            filled: false,
            getElevation: 1,
            getLineColor: [255, 0, 0],
            getLineWidth: d => 8,
            getPolygon: d => d.contour,
            lineWidthMinPixels: 1,
            stroked: true,
            wireframe: true,
            opacity: 1,
            pickable: true,
            onClick: (info, event) => {
                console.log(info.object);
            },
            /*onHover: (info, event) => {
                console.log('Hovered', info)
            }*/
        }
    );

    

    const layer_1 = new PolygonLayer(
        {
            id: 'PolygonLayer',
            data: dataArea,
            extruded: true,
            filled: true,
            getElevation: 1,
            getFillColor: [255, 255, 255],
            getLineColor: [80, 80, 80],
            getLineWidth: d => 1,
            getPolygon: d => d.contour,
            lineWidthMinPixels: 1,
            stroked: true,
            wireframe: true,
            opacity: 0.2,
            pickable: true,
            onClick: (info, event) => {
            let pos = {
                    left:event.center.x,
                    top:event.center.y,
                    point_count:1,
                    points:[],
                    areadata:info.object
                }
                setPop(pos);
                setHover(!hover);
            },
            //onHover: (info, event) => {
                //console.log('Hovered', event)
            //}
        }
    );
    const layer =clustered ? new IconClusterLayer(
        /*{
        id:"scatterplotForMarkersTest",
        data : plantationMarkers,
        getPosition:(d) => [d.lng, d.lat],
        getColor:[255, 255, 255],
        getRadius:1000,

        }*/
        {
    id: 'icon-cluster-layer',
    data: plantationMarkers,
    pickable: true,
    // iconAtlas and iconMapping are required
    // getIcon: return a string
    iconAtlas: atlas,
    iconMapping: mapping,
    sizeScale: 40,
    onClick: (info, event) => {
            let pos = {
                    left:event.center.x,
                    top:event.center.y,
                    point_count:info.object.point_count,
                    points:info.objects,
                    areadata:null
                }
                setPop(pos);
                setHover(!hover);
            },
    
    getPosition: d => [d.position.lng,d.position.lat],
  
  }
        
    ) :
    new IconLayer({
        id: 'iconlayer',
        data: plantationMarkers,
        pickable: true,
        iconAtlas: atlas,
        iconMapping: mapping,
        getPosition: (d) => [d.position.lng,d.position.lat],
        getIcon: d => 'marker',
        sizeUnits: 'meters',
        sizeScale: 300,
        sizeMinPixels:20,
        onClick: (info, event) => {console.log('Clicked:',info)
            let pos = {
                    left:event.center.x,
                    top:event.center.y,
                    point_count:1,
                    points:[info.object],
                    areadata:null,
                }
                setPop(pos);
                setHover(!hover);
            },
        }
    );


    return (
        <>
            {(isFetching || isLoading) && <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}>

                <CircularProgress color="inherit" />
            </Backdrop>}


            <BaseLayout breadcrumb={[{ label: "Acceuil" }, { label: t('menu.geoloc') }]}>
         
                <Card>
                <Grid container spacing={2}>
                <Grid item xs={12} md={4} >
                <CardActions  style={{ justifyContent: "space-between" }}>
                <MKButton
                        variant="contained"
                        color="warning"
                        style={{ width: "50%" }}
                        onClick = {()=> handleDownload()}
                      >
                        {t("actions.download")+" Gpx"}
                </MKButton>
                </CardActions>
                </Grid>
                <Grid item xs={12} md={4}>
                <CardActions  style={{ justifyContent: "space-between" }}>
                <MKButton
                        variant="contained"
                        color="warning"
                        style={{ width: "50%" }}
                        onClick = {()=> handleDownloadKml()}
                      >
                        {t("actions.download")+" Kml"}
                </MKButton>
                </CardActions>
                </Grid>
                </Grid>
                <RegionSelect selectedRegion={selectedRegion} setSelectedRegion = {setSelectedRegion} />
                <SearchBarPlantation code={searchCode} setCode={setSearchCode} />
                <SearchBarCoop setOpen= {setSearchPop} />
                

                <Box sx={{ width: '90%' }}>
        
                <Box sx={{ p: 3 }}>
                    {selectedRegion === '0' && (
                        <MKBox
                            variant="gradient"
                            bgColor="light"
                            borderRadius="lg"
                            coloredShadow="info"
                            textAlign="center"
                            sx={{ p: 5 }}
                            style={{ height: '80vh', width: '90vw', position: 'relative' }}
                        >
                            <APIProvider apiKey={'AIzaSyAmZOaeihx0QrQKdyj9eudlCn9LqY54CU0'}>
                                <DeckGL
                                    layers={[layer_2, layer_1, layer]}
                                    initialViewState={INITIAL_VIEW_STATE}
                                    controller={true}
                                    onViewStateChange={limitTiltRange}
                                >
                                    <Map mapTypeId={'hybrid'} mapId={'bd6d518702de0352'} gestureHandling={'greedy'} disableDefaultUI={true}>
                                    </Map>
                                </DeckGL>
                            </APIProvider>
                        </MKBox>
                    )}
                {ViewStates.map((viewState,index)=>{

                    return(<>{selectedRegion === `${viewState.id}` && (
                        <MKBox
                            id ={index}
                            variant="gradient"
                            bgColor="light"
                            borderRadius="lg"
                            coloredShadow="info"
                            textAlign="center"
                            sx={{ p: 5 }}
                            style={{ height: '80vh', width: '90vw', position: 'relative' }}
                        >
                            <APIProvider apiKey={'AIzaSyAmZOaeihx0QrQKdyj9eudlCn9LqY54CU0'}>
                                <DeckGL
                                    layers={[layer_1, layer,layer_2]}
                                    initialViewState={viewState.view}
                                    controller={true}
                                    onViewStateChange={limitTiltRange}
                                >
                                    <Map mapTypeId={'hybrid'} mapId={'bd6d518702de0352'} gestureHandling={'greedy'} disableDefaultUI={false}>
                                    </Map>
                                </DeckGL>
                            </APIProvider>
                        </MKBox>
                    )}</>);


                })}

                </Box>
            </Box>
            </Card>

                <CustomPopover open = {hover} 
                position= {pop} 
                handleClose={()=>{setHover(0)}}
                clustered={clustered}
                handleChange={(event)=>{setClustered(event.target.checked), setHover(0)}} />
                <PopSearch open={searchPop} handleClose={() => setSearchPop(false)} setCoop={setCoop} />

            </BaseLayout>

            </>
            
    );
}

const CustomPopover= ({open, position,handleClose,clustered,handleChange})=>{

   return(

          <Popover
        id={"defined"}
        open={open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: position.top, left: position.left }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
    >
  <Card>
    <MKBox
    variant="gradient"
    color="light"
    borderRadius="lg"
    coloredShadow="info"
    textAlign="center"
    sx={{ p: 5}}>
    <Stack direction = "column" spacing={2} >
        <Stack direction="row" spacing={1}>
      <Chip label="Nombre plantations dans le groupe" />
      <Chip label={position.point_count} variant="outlined" />
    </Stack>


    {  
        Array.isArray(position.points) ? position.points.map(plantation => {return(
        <Stack direction="row" spacing={1}>
            <Chip label="Code plantation" />
            <Chip label={plantation.name} variant="outlined" />
        </Stack> );   
    }):<div>Uncluster for data</div>
    
    }

    {
        position.areadata!=null ?
        <>
             <Stack direction="column" spacing={1} >
                <Stack direction="row" spacing={1}>
                    <Chip label="Code plantation" />
                    <Chip label={position.areadata.name} variant="outlined" />
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Chip label="Superficie" />
                    <Chip label={position.areadata.area.toFixed(3)+"  m2"} variant="outlined" />
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Chip label="Production" />
                    <Chip label={position.areadata.production} variant="outlined" />
                </Stack>

             </Stack> </>
             : <div>_____</div>}


    <Stack direction="row" spacing={1} >
        <Chip label = "Grouper les marqueurs"/>
        <Checkbox
            checked={clustered}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            
        />
    </Stack>

    </Stack>
    </MKBox>
  </Card>
   </Popover>
   );
   
};

const SearchBarCoop = ({ setOpen }) => {
  const [filterEmail, setFilterEmail] = useState('');
  const [filterNom, setFilterNom] = useState('');

  const dispatch = useDispatch();

  const handleSearch = () => {
    const filters = {
      nom: filterNom,
      email: filterEmail
    };

    const dto = {
      criteria: filterObject(filters),
      page: 0,
      size: 200,
    };
    dispatch(cooperativeActions.search(dto));
    setOpen(true);
  };

  const filterObject = (obj) => {
    const filtered = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        filtered[key] = obj[key];
      }
    });
    return filtered;
  };

  return (
    <MKBox component="section" my={1} py={1}>
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
         
          <Grid item xs={12} md={3}>
            <TextField fullWidth variant="standard" label="Nom Coopérative" value={filterNom} onChange={(e) => setFilterNom(e.target.value)} />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField fullWidth variant="standard" label="Email" value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} />
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Button fullWidth variant="contained" onClick={handleSearch} color="info" size="large">
              Lancer la recherche
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button fullWidth variant="contained" onClick={()=>{setCoopFilter(0)}} color="info" size="large">
              Reinisialiser
            </Button>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};


const SearchBarPlantation = ({ setCode }) => {
  const [searchCode, setSearchCode] = useState('');

  return (
    <MKBox component="section" my={1} py={1}>
      <Container>
        <Grid container justifyContent="center" spacing={2} xs={12} lg={12} mx="auto" textAlign="center">
          <Grid item xs={12} md={6}>
            <TextField fullWidth variant="standard" label="Filtrer par code plantation" value={searchCode} onChange={(e) => setSearchCode(e.target.value)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button fullWidth variant="contained" onClick={() => setCode(searchCode)} color="info" size="large">
              Lancer la recherche
            </Button>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

const RegionSelect = ({selectedRegion,setSelectedRegion}) => {
  
  const handleChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  return (
        <MKBox component="section" my={1} py={1}>
            <Container>
                <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
                    <Grid item xs={12}  md={12}>
                        <FormControl fullWidth>
                            <InputLabel id="region-select-label">Selectionner Region</InputLabel>
                            <Select
                                labelId="region-select-label"
                                value={selectedRegion}
                                onChange={handleChange}
                            >
                                {Object.entries(REGION_OPTIONS).map(([key, value]) => (
                                    <MenuItem key={key} value={`${key}`}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
  );
};

const getAllCodePlantation = (sections) => {
    const codePlantations = [];

    sections.forEach(section => {
      section.planteurs.forEach(planteur => {
        planteur.plantations.forEach(plantation => {
          codePlantations.push(plantation.codePlantation);
        });
      });
    });

    return codePlantations;
  };


const PopSearch = ({ open, handleClose, setCoop }) => {

    const {isFetching}=useSelector(state=>state.cooperative);
  
  return (
    <Dialog PaperProps={{
        style: {
          width: '80%',
          height: '80%',
          margin: 'auto',
        },
      }} open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Resultas</Typography>
          <Button onClick={handleClose} color="inherit">Fermer</Button>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isFetching && (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {<CoopResults  setCoopFilter={setCoop} handleClose={handleClose} />}
      </DialogContent>
    </Dialog>
  );
};

/*const ReturnSearch = ({ search, setOpen }) => {
  if (search === 1) return <SearchBarCoop setOpen={setOpen} />;
  if (search === 2) return <SearchBarPlanteur setOpen={setOpen} />;
  if (search === 3) return <SearchBarPlantation setOpen={setOpen} />;
  return null;
};*/

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const REGION_OPTIONS = {
    0:"Vue Generale",
    1:"DISTRICT AUTONOME D'ABIDJAN",
    2:"AGNEBY-TIASSA",
    3:"BAFING",
    4:"BAGOUE",
    5:"BELIER",
    6:"BERE",
    7:"BOUNKANI",
    8:"CAVALLY",
    9:"FOLON",
    10:"GBEKE",
    11:"GBOKLE",
    12:"GOH",
    13:"GONTOUGO",
    14:"GRANDS-PONTS",
    15:"GUEMON",
    16:"HAMBOL",
    17:"HAUT-SASSANDRA",
    18:"IFFOU",
    19:"INDENIE-DJUABLIN",
    20:"KABADOUGOU",
    21:"LOH-DJIBOUA",
    22:"MARAHOUE",
    23:"ME",
    24:"MORONOU",
    25:"N'ZI",
    26:"NAWA",
    27:"PORO",
    28:"SAN-PEDRO",
    29:"SUD-COMOE",
    30:"TCHOLOGO",
    31:"TONKPI",
    32:"WORODOUGOU",
    33:"DISTRICT AUTONOME DE YAMOUSSOUKRO"
};


const Markers = ({ points }) => {
    const [markers, setMarkers] = useState({})
    const clusterer = useRef(null)
    const map = useMap()

    useEffect(() => {
        if (!map) return
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({ map })
        }
    }, [map])

    useEffect(() => {
        clusterer.current.clearMarkers()
        clusterer.current.addMarkers(Object.values(markers))
    }, [markers])

    const setMarkerRef = (marker, key) => {
        if (marker && markers[key]) return
        if (!marker && !markers[key]) return

        setMarkers(prev => {
            if (marker) {
                return { ...prev, [key]: marker }
            } else {
                const newMarkers = { ...prev }
                delete newMarkers[key]
                return newMarkers
            }
        })
    }

    return (
        <>
            {points.map(point => (
                <AdvancedMarker
                    position={point.position}
                    key={point.key}
                    ref={marker => setMarkerRef(marker, point.key)}
                >
                    <Pin background={'#5D3FD3'} glyphColor={'#CF9FFF'} borderColor={'#000'} />
                </AdvancedMarker>
            ))}
        </>
    )
};


export default Geolocalisation;
