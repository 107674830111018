import instance from "./AxiosInterceptor";

export const create = async (data) => {
    try {
        const response = await instance.post(`/couche/create`, data);
        console.log('Response:', response.data); // Log the response data
        return response.data; // Return the response data if needed
    } catch (error) {
        console.error('Error creating couche:', error);
        throw error; // Throw the error to handle it in the caller function
    }
};

export const list = () => {
    return instance.get(`/couche/list`);
};

export const update = (id, data) => {
    return instance.put(`/couche/update/${id}`, data);
};

export const deleteCouche = (id) => {
    return instance.delete(`/couche/delete/${id}`);
};

export const get = async (id) => {
    try {
       return await instance.get(`/couche/${id}`);  
    } catch (error) {
        console.error('Error fetching couche:', error);
        throw error; 
    }
};

export default { create, list, update, deleteCouche, get };
