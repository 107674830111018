import { Backdrop, CircularProgress,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    Grid,
    Button, } from "@mui/material";
    import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import { styled } from '@mui/material/styles';
import { DataGrid } from "@mui/x-data-grid";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { plantationActions } from "sagas/plantationSlice";
import Paper from '@mui/material/Paper';
import { dataActions } from "sagas/dataSlice";
import axios from "axios";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { APIProvider, AdvancedMarker, Map, Marker, limitTiltRange, useMap, Pin } from "@vis.gl/react-google-maps";
const PlantationSearchResult = () => { 
          const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
            backgroundColor: '#ffa5000d',
            boxShadow: 10, 
            marginTop:40,
            '& .MuiTablePagination-root': {
              display: 'none',
            },
              // Use Material-UI theme colors
          }));
        const { t } = useTranslation('translation');
        const { totalUsers, totalPlanteurs, totalPMales, totalPFemales, totalPlantations, plantationTypes,totalA,totalB,totalCoops,totalNbrEnfants,totalNbrEnfantsRisque,totalNbrEnfantsTravail,totalNbrEnfantsActeNaissance, totalNbrEnfantsNonScolarises} = useSelector(state => state.data);
        const columns = [
        { field: 'codePlantation', headerAlign: 'center', align:'center', headerName: "Code", width: 150 },
        { field: 'plantationAge', headerAlign: 'center',align:'center', headerName:"Age" , width: 50 },        
        { field: 'anneeCreation', headerAlign: 'center',align:'center', headerName:'Annee creation', width: 150 },
        { field: 'typePaiement', headerAlign: 'center',align:'center', headerName:'Type Paiement' , width: 150 },
        { field: 'tempsTravail', headerAlign: 'center',align:'center', headerName:'Temps travail' , width: 150 },
        {field: 'superficie', headerAlign: 'center',align:'center', headerName:'Superficie en m2' , width: 150},
        {field: 'pourcentageTaille', headerAlign: 'center',align:'center', headerName:'Taille réalisée' , width: 100},
        { field: 'utilisationEngrais', headerAlign: 'center',align:'center', headerName:'Utilisation Engrais', width: 150 },
        {field: 'periodeutilisationEngrais', headerAlign: 'center',align:'center', headerName:'Periode Utilisation Engrais' , width: 200},
        {field: 'quantiteEngrais', headerAlign: 'center',align:'center', headerName:'Quantite Engrais' , width: 100},
        { field: 'quantiteMainOeuvre', headerAlign: 'center',align:'center', headerName:'Quantite Main d\'oeuvre:', width: 150 },
        { field: 'traitementPesticide', headerAlign: 'center',align:'center', headerName:'Traitement pesticides', width: 150 },
        { field: 'fournisseurPesticide', headerAlign: 'center',align:'center', headerName: 'Fournisseur pesticides', width: 150 },
        { field: 'description', headerAlign: 'center',align:'center', headerName:'Description' , width: 150 },
    ]
    const [filterCode, setFilterCode] = useState(''); // Filter nom state
    const [filterPlanteur, setFilterPlanteur] = useState(''); // Filter prenom state
    const [filterPesticides, setFilterPesticides] = useState(''); // Filter genre state
    const [filterEngrais, setFilterEngrais] = useState(''); // Filter age state
    const [searchTerm, setSearchTerm] = useState('');
    const [plantation, setplantation]=useState([]);
    const [geoLib, setGeoLib] = useState(null);
    const { plantations, isFetching  } =  useSelector((state) => state.plantation);
    console.log(plantations);
    // const [paginationModel, setPaginationModel] = useState({
    //     pageSize: 20,
    //     page: 0,
    // });    
    const dispatch = useDispatch();
    useEffect(()=>{
      const getGeolibrary = async () => {
            const { spherical } = await google.maps.importLibrary("geometry");
            return spherical;
        }

      getGeolibrary().then(spherical => setGeoLib(spherical)).catch(console.error);
      dispatch(dataActions.loadData());
    },[]);

   


    let plantationsCopie =[]
   if(plantations.length != 0){

     plantations.map(mapped_plantation=>{
    if(mapped_plantation != null && mapped_plantation != undefined){


    
       let area = {superficie:0};
       console.log(mapped_plantation);
       let plantationCopy = {...mapped_plantation,...area}
       let parsedContour = JSON.parse(mapped_plantation.contour);
       if(parsedContour?.length>3){

            let areaCalculatorArray = [];
            parsedContour.map(point => {
               
                let areaPoint = {

                    lat: point.latitude,
                    lng: point.longitude
                };
                areaCalculatorArray.push(areaPoint);
                
            });

            
            if (geoLib) {
                let superficie = geoLib.computeArea(areaCalculatorArray);
                plantationCopy.superficie = superficie;
                console.log(superficie);
                
            }
            else {
              plantationCopy.superficie="Non calculée";
                console.log('cannot calculate rn ');
            }

            

            }
            else {
              plantationCopy.superficie="Non definie"
            }

            plantationsCopie.push(plantationCopy);}

       
     });

   }


    return (
        <>
            {isFetching &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>}

            
            <Paper component="CounterCard" elevation={3} sx={{ padding: '16px', backgroundColor: '#f5f5f5', border: '1px solid #ddd' }}>
             <APIProvider apiKey={'AIzaSyAmZOaeihx0QrQKdyj9eudlCn9LqY54CU0'}>
             </APIProvider>
              
            

                {(!plantationsCopie || plantationsCopie?.length == 0) ? <div>{t('messages.nodata')}</div> :
                    <>                          

                        <div style={{ height: 700, width: '110%' ,alignItems:'center' }}>
                            <StyledDataGrid
                                rows={plantationsCopie}
                                columns={columns}
                                getRowId={(r) => {
                                    if (r === null) { return null }
                                    return r.id
                                }}
                                sx={{
                                  borderColor: '#000000',
                                  boxShadow: 10,                                  
                                }}
                                // paginationModel={page}
                                // onPaginationModelChange={setPage}
                            /> 
                           
                           
                        </div>
                        
                    </>}

            </Paper>

        </>
    )
}

export default PlantationSearchResult;

/* Long id;
    @ManyToOne(fetch= FetchType.LAZY)
    @JoinColumn(name = "PLANTEUR_ID")
    Planteur planteur;
    @ManyToOne
    @JoinColumn(name = "COOPERATIVE_ID")
    Cooperative cooperative;
    @ManyToOne
    @JoinColumn(name = "USER_ID")
    User user;
    String codePlantation;
    String description;
    String section;
    int plantationAge;
    int anneeCreation;
    @Enumerated(EnumType.STRING)
    UtilisationMainOeuvre utilisationMainOeuvre;
    @Enumerated(EnumType.STRING)
    TypePaiement typePaiement;
    @Enumerated(EnumType.STRING)
    MoyenPaiement moyenPaiement;
    @Enumerated(EnumType.STRING)
    TempsTravail tempsTravail;
    @Enumerated(EnumType.STRING)
    OrigineMainOeuvre origineMainOeuvre;
    @Enumerated(EnumType.STRING)
    UtilisationEngrais utilisationEngrais;
    String autreOrganisationAchat;
    @Enumerated(EnumType.STRING)
    TraitementPesticide traitementPesticide;
    @Enumerated(EnumType.STRING)
    FournisseurPesticide fournisseurPesticide;
    String typePesticide;
    String autreTypePesticide;
    String frequenceUtilisation;
    String autreFrequenceUtilisation;
    String periodeTraitement;
    String codePlantationBCS;
    @Column(length=1000)
    String contour;
    @Enumerated(EnumType.STRING)
    QuantiteMainOeuvre quantiteMainOeuvre;
    String typePlante;*/