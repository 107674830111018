import { createModule } from "saga-slice";
import { call, put } from "redux-saga/effects";
import { create, list, update, deleteCooperative, get,searchCoop, downloadExcel } from "../services/cooperativeService"; // Import the `get` function

const cooperativeSlice = createModule({
  name: "cooperative",
  initialState: {
    cooperatives: [],
    current: null,
    isFetching: false,
    error: null,
  },
  reducers: {
    startFetching: (state) => {
      state.isFetching = true;
    },
    update: (state) => {
      state.isFetching = true;
    },
    create: (state) => {
      state.isFetching = true;
    },
    get: (state) => {
      state.isFetching = true;
    },
    delete: (state) => {
      state.isFetching = true;
    },
    list: (state) => {
      state.isFetching = true;
    },
    finishFetching: (state) => {
      state.isFetching = false;
    },
    fetchCurrentSuccess: (state, payload) => {
      state.current = payload.body;
    },
    fetchSuccess: (state, payload) => {
      state.cooperatives = payload;
    },
    setCurrent: (state, payload) => {
      state.current = payload; // Set the current cooperative
    },
    fetchError: (state) => {
      state.error = "An error occurred";
    },
    search: (state)=>{
      state.isFetching =true;
    },
    download: (state)=>{
      state.isFetching =true;
    }
  },
  sagas: (A) => ({
    *[A.create]({ payload }) {
        try {
          const { data } = yield create(payload.data);
          console.log(payload.data); // Log the data
          yield put(A.finishFetching());
          yield call(payload.onSuccess, data);
        } catch (e) {
          console.log(e);
          yield put(A.finishFetching());
          yield put(A.fetchError());
        }
    },
    *[A.update]({ payload }) {
      try {
        const { data } = yield update(payload.id, payload.data);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data.body);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.delete]({ payload }) {
      try {
        console.log("here")
        yield deleteCooperative(payload.id);
        yield put(A.finishFetching());
        yield call(payload.onSuccess);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.list]({ payload }) {
      try {
        const { data } = yield list();
        yield put(A.finishFetching());
        yield put(A.fetchSuccess(data?.body)); // Make sure `data` has the correct structure
        if (payload && payload.onSuccess) {
          yield call(payload.onSuccess, data);
        }
        console.log("Fetched cooperatives:", data);
      } catch (error) {
        console.error("Error fetching cooperatives:", error);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.get]({ payload }) {
      try {
          const { data } = yield get(payload.id); 
          yield put(A.finishFetching());
          yield call(payload.onSuccess, data.body);
      } catch (e) {
          console.log(e); 
          yield put(A.finishFetching()); 
          yield put(A.fetchError()); 
      }
  },

  *[A.search]({ payload }) {
      try {
          const { data } = yield searchCoop(payload);
          console.log("coop",data?.body.content);
          yield put(A.fetchSuccess(data?.body.content));
          yield put(A.finishFetching());
      } catch (e) {
          console.log(e); 
          yield put(A.finishFetching()); 
          yield put(A.fetchError()); 
      }
  },
  *[A.download]({payload}) {
    try {
          yield downloadExcel(payload);
          yield put(A.finishFetching());
      } catch (e) {
          console.log(e); 
          yield put(A.finishFetching()); 
          yield put(A.fetchError()); 
      }
  }

  }),
});

export default cooperativeSlice;
export const cooperativeActions = cooperativeSlice.actions;