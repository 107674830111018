import instance from "./AxiosInterceptor";
import config from "../config";


export const contourRegions = async () => {
    console.log("here");
    return instance.get(`${config.baseURL}/region/contours`);
};

export const listRegions = async () => {
    console.log("here");
    return instance.get(`${config.baseURL}/region/list`);
};



