import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  Snackbar,
  Alert,
  FormHelperText,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography
} from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cooperativeActions } from "sagas/cooperativeSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { COOP_MANAGE_PAGE,SECTION_VIEW_EDIT_FORM, SECTION_FORM } from "router/constants";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKButton from "components/MKButton";

const FormCooperative = () => {
  const { id, operation } = useParams();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { isFetching } = useSelector((state) => state.data);
  const [open, setOpen] = useState(false);
  const [sections, setSections] = useState([]);
  const [planteurs,setPlanteurs]=useState([]);
  const [plantations,setPlantations]=useState([]);
  function onlyUnique(value,index,array) {

    console.log(value.id,index);

  let val = array.find(item=>item.id===value.id);
    console.log(val.id,index);
    console.log(array.indexOf(val) === index);
  return array.indexOf(val) === index;
}


  useEffect(() => {
    if (id) {
        let payload = {
            id: id,
            onSuccess: (data) => {
                setData(data);
                let sectionArray=data.sections.filter(onlyUnique);
                setSections(sectionArray);
                console.log(sectionArray);
                let planteurArray=[];
                
                for(let i=0; i<sectionArray.length;i++){
                  sectionArray[i].planteurs.map(planteur=>planteurArray.push(planteur));
                }

                console.log(planteurArray);
                setPlanteurs(planteurArray);
                let plantationArray = [];
                planteurArray.map((planteur)=>{
                  plantationArray=plantationArray.concat(planteur.plantations);
                });
                setPlantations(plantationArray);
            }
        }
        dispatch(cooperativeActions.get(payload))
    }
  }, [dispatch, id]);

    const handleViewEdit = (cooperativeId, operation, id) => {
    navigate(
      `${SECTION_VIEW_EDIT_FORM.replace(":cooperativeId",cooperativeId).replace(
        ":id",
        id
      ).replace(":operation", operation)}`
    );
  };

  const handleAddNewSection = (cooperativeId) => {
    navigate(`${SECTION_FORM.replace(":cooperativeId",cooperativeId)}`);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(COOP_MANAGE_PAGE)
  };

  const initialValues = {
    nom: data?.nom || "",
    reference: data?.reference || "",
    description: data?.description || "",
    nombreParcelles: data?.nombreParcelles || "",
    email: data?.email || "",
    adresse: data?.adresse || "",
    telephone:data?.telephone || "",
    siteWeb:data?.siteWeb|| "",
    regionId:data?.regionId || 0
    

  };

  const validationSchema = Yup.object().shape({
    nom: Yup.string().required(),
    reference: Yup.string().required(),
    description: Yup.string().required(),
    email: Yup.string().required(),
    telephone: Yup.number().required(),
    adresse: Yup.string().required(),  
    });

  const onSubmit = (values) => {
    console.log(values);
    try {
      const payload = {
        id:id,
        data: values,
        onSuccess: () => {
            setOpen(true); 
        },
      };
  
      if (operation === 'edit') {
        dispatch(cooperativeActions.update(payload));
        console.log('Cooperative updated successfully');
      } else {
        dispatch(cooperativeActions.create(payload));
        console.log('Cooperative added successfully');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema,
    onSubmit,
  });

  return (
   <>
    {isFetching && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <BaseLayout
        breadcrumb={[{ label: "Accueil" }, { label: t("menu.dashboard") }]}
      >
        <Paper sx={{ p: 2, mt: 2, boxShadow: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
             <Grid item xs={6}>
                 <InputLabel id="region-select-label">Selectionner Region</InputLabel>
                            <Select
                                disabled={operation === "view"}
                                labelId="region-select-label"
                                value={Number(formik.values.regionId)}
                                onChange={e => formik.setFieldValue('regionId', Number(e.target.value))}
                            >
                                {Object.entries(REGION_OPTIONS).map(([key, value]) => (
                                    <MenuItem key={key} value={Number(key)}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
              </Grid>


              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="siteWeb"
                  name="siteWeb"
                  label={"Site Web"}
                  value={formik.values.siteWeb}
                  disabled={operation === "view"}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.entreprise &&
                    Boolean(formik.errors.entreprise)
                  }
                  helperText={
                    formik.touched.siteWeb && formik.errors.siteWeb
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="nom"
                  name="nom"
                  label={"Nom"}
                  value={formik.values.nom}
                  disabled={operation === "view"}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nom && Boolean(formik.errors.nom)
                  }
                  helperText={formik.touched.nom && formik.errors.nom}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label={t("couche.description")}
                  value={formik.values.description}
                  disabled={operation === "view"}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="reference"
                  name="reference"
                  label={"Reference"}
                  disabled={operation === "view"}
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reference &&
                    Boolean(formik.errors.reference)
                  }
                  helperText={
                    formik.touched.reference &&
                    formik.errors.reference
                  }
                />
              </Grid>

                <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="adresse"
                  name="adresse"
                  label={"Adresse"}
                  disabled={operation === "view"}
                  value={formik.values.adresse}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.adresse &&
                    Boolean(formik.errors.adresse)
                  }
                  helperText={
                    formik.touched.adresse &&
                    formik.errors.adresse
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label={"email"}
                  disabled={operation === "view"}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.adresse &&
                    Boolean(formik.errors.adresse)
                  }
                  helperText={
                    formik.touched.adresse &&
                    formik.errors.adresse
                  }
                />
              </Grid>
              
                <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="telephone"
                  name="telephone"
                  label={"Telephone"}
                  disabled={operation === "view"}
                  value={formik.values.telephone}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.telephone &&
                    Boolean(formik.errors.telephone)
                  }
                  helperText={
                    formik.touched.telephone &&
                    formik.errors.telephone
                  }
                />
              </Grid>

            
              {operation !== "view" && (
                <Grid item xs={12}>
                    <MKButton type="submit" variant="contained" color="info">
                      {operation === "edit" ? t("actions.edit") : t("actions.save")}
                    </MKButton>
                </Grid>
                )}
            </Grid>
          </form>
          <h1>Sections</h1>
          <Paper elevation={0} style={{ padding: "20px", marginTop: "20px" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
           {sections.map((section)=> (<Card
                key={section.id}
                variant="outlined"
                style={{
                  width: "30%",
                  marginTop:"30px",
                  marginBottom: "20px",
                  marginRight: "3%",
                }}
              >
                <CardContent>
                  <DefaultInfoCard
                    color="info"
                    icon="icon-name"
                    title={section.nom}
                    description={
                      <>
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Adresse"}:{" "}
                        </Typography>
                        {section.adresse}
                        <br />
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Code"}:{" "}
                        </Typography>
                        {section.code}
                      </>
                    }
                  />
                </CardContent>
                {false ? (
                  <CardActions style={{ justifyContent: "space-between" }}>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                      onClick={() => {}}
                    >
                      {t("actions.edit")}
                    </MKButton>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                    >
                      {t("actions.delete")}
                    </MKButton>
                  </CardActions>
                ) : (
                  <>
                    <CardActions style={{ justifyContent: "space-between" }}>
                      <MKButton
                        variant="contained"
                        color="error"
                        style={{ width: "30%" }}
                        onClick={()=>{handleViewEdit(data.id,"view",section.id)}}
                      >
                        {t("actions.viewForm")}
                      </MKButton>
                      <MKButton
                        variant="contained"
                        color="error"
                        style={{ width: "30%" }}
                        onClick={()=>{handleViewEdit(data.id,"edit",section.id)}
                        }
                      >
                        {t("actions.edit")}
                      </MKButton>
                      <MKButton
                        variant="contained"
                        color="error"
                        style={{ width: "30%" }}
                      >
                        {t("actions.delete")}
                      </MKButton>
                    </CardActions>
                  </>
                )}
              </Card>))}

              <MKButton
                        variant="contained"
                        color="info"
                        style={{ width: "30%" }}
                        onClick={()=>{handleAddNewSection(data.id)}}
                      >
                        {"Ajouter Une Section"}
                      </MKButton>

              </div>
              </Paper>

                <h1>Planteurs</h1>
          <Paper elevation={0} style={{ padding: "20px", marginTop: "20px" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
           {planteurs.map((planteur)=> (<Card
                key={planteur.id}
                variant="outlined"
                style={{
                  width: "30%",
                  marginTop:"30px",
                  marginBottom: "20px",
                  marginRight: "3%",
                }}
              >
                <CardContent>
                  <DefaultInfoCard
                    color="info"
                    icon="icon-name"
                    title={planteur.nom+" "+planteur.prenom}
                    description={
                      <>
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Code"}:{" "}
                        </Typography>
                        {planteur.codeProducteur}
                        </>
                    }
                  />
                </CardContent>
                {false ? (
                  <CardActions style={{ justifyContent: "space-between" }}>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                      onClick={() => {}}
                    >
                      {t("actions.edit")}
                    </MKButton>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                    >
                      {t("actions.delete")}
                    </MKButton>
                  </CardActions>
                ) : (
                  <>
                    <CardActions style={{ justifyContent: "space-between" }}>
                      
                    
                    </CardActions>
                  </>
                )}
              </Card>))}

              </div>
              </Paper>
           
    <h1>Plantations</h1>
          <Paper elevation={0} style={{ padding: "20px", marginTop: "20px" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
           {plantations.map((plantation)=> (<Card
                key={plantation.id}
                variant="outlined"
                style={{
                  width: "30%",
                  marginTop:"30px",
                  marginBottom: "20px",
                  marginRight: "3%",
                }}
              >
                <CardContent>
                  <DefaultInfoCard
                    color="info"
                    icon="icon-name"
                    title={plantation.codePlantation}
                    description={
                      <>
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Certification"}:{" "}
                        </Typography>
                        {plantation.certification}
                        </>
                    }
                  />
                </CardContent>
                {false ? (
                  <CardActions style={{ justifyContent: "space-between" }}>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                      onClick={() => {}}
                    >
                      {t("actions.edit")}
                    </MKButton>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                    >
                      {t("actions.delete")}
                    </MKButton>
                  </CardActions>
                ) : (
                  <>
                    <CardActions style={{ justifyContent: "space-between" }}>
                     
                    </CardActions>
                  </>
                )}
              </Card>))}

              </div>
              </Paper>
          
        </Paper>
      </BaseLayout>
      <Snackbar open={open} autoHideDuration={1100} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled">
          {t('messages.success')}
        </Alert>
      </Snackbar>
   </>
  )
};

const REGION_OPTIONS = {
    0:"Non determine",
    1:"DISTRICT AUTONOME D'ABIDJAN",
    2:"AGNEBY-TIASSA",
    3:"BAFING",
    4:"BAGOUE",
    5:"BELIER",
    6:"BERE",
    7:"BOUNKANI",
    8:"CAVALLY",
    9:"FOLON",
    10:"GBEKE",
    11:"GBOKLE",
    12:"GOH",
    13:"GONTOUGO",
    14:"GRANDS-PONTS",
    15:"GUEMON",
    16:"HAMBOL",
    17:"HAUT-SASSANDRA",
    18:"IFFOU",
    19:"INDENIE-DJUABLIN",
    20:"KABADOUGOU",
    21:"LOH-DJIBOUA",
    22:"MARAHOUE",
    23:"ME",
    24:"MORONOU",
    25:"N'ZI",
    26:"NAWA",
    27:"PORO",
    28:"SAN-PEDRO",
    29:"SUD-COMOE",
    30:"TCHOLOGO",
    31:"TONKPI",
    32:"WORODOUGOU",
    33:"DISTRICT AUTONOME DE YAMOUSSOUKRO"
};

export default FormCooperative;