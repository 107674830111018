import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import {listRegions, contourRegions} from "services/regionService";
import { login } from "services/userService";

const regionSlice = createModule({
  name: 'region',
  initialState: {
    regions: [],
    isFetching: false,
    error: null,
  },
  reducers: {
    fetchRegions(state) {
      state.isFetching = true;
    },
    fetchContour(state){
      state.isFetching=true;
    },
    fetchSuccess(state, payload) {
      state.isFetching = false;
      if (Array.isArray(payload))
        state.regions = payload;
      else {
        state.regions = [];
        state.regions.push(payload);
      }
    },
    fetchError: (state) => {
      state.error = "An error occured";
    },
    finishFetching(state) {
      state.isFetching = false;
    },
  },
  sagas: (A) => ({
    *[A.fetchRegions]() {
      try {
        const { data } = yield listRegions();
        yield put(A.finishFetching());
        yield put(A.fetchSuccess(data?.body));
      } catch (error) {
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.fetchContour](){
      try {
        const { data } = yield contourRegions();
        yield put(A.finishFetching());
        yield put(A.fetchSuccess(data?.body));
      } catch (error) {
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    }
  
  })
});


export default regionSlice;
export const regionActions = regionSlice.actions;