import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Backdrop,
  CircularProgress,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography ,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Button
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cooperativeActions } from "sagas/cooperativeSlice";
import BaseLayout from "layouts/sections/components/BaseLayout";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKButton from "components/MKButton";
import { COOP_FORM, COOP_VIEW_EDIT_FORM } from "router/constants";
import { COOP_MANAGE_PAGE } from "router/constants";

const Cooperative = () => {
  const { t } = useTranslation("translation");
  const { cooperatives, isFetching } = useSelector((state) => state.cooperative);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterNom,setFilterNom]=useState('');
  const [filterEmail,setFilterEmail]=useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);






  const handleSearch = ()=>{
    const filters = {
      nom:filterNom,
      email:filterEmail
    };

    dispatch(cooperativeActions.filterCooperatives(filters));
  }
  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    dispatch(cooperativeActions.list());
  }, []);

  const handleViewEdit = (operation, id) => {
    navigate(
      `${COOP_VIEW_EDIT_FORM.replace(":operation", operation).replace(
        ":id",
        id
      )}`
    );
  };

  const handleAddNewCooperative = () => {
    navigate(COOP_FORM);
  };

  const handleDelete = (id) => {
    dispatch(
      cooperativeActions.delete({
        id: id,
        onSuccess: () => {
          dispatch(cooperativeActions.list());
        },
      })
    );
    handleCloseDeleteDialog();
  };

  return (
    <>
      {isFetching && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <BaseLayout
        breadcrumb={[{ label:"Acceuil" }, { label: "Cooperative" }]}
      >
       <Paper component="form" elevation={3} sx={{ padding: '16px', backgroundColor: '#f5f5f5', border: '1px solid #ddd' }}>
      <Typography variant="h6" gutterBottom sx={{ fontSize: '18px', fontWeight: 'bold' }}>
        Options de recherche
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl fullWidth>
            <InputLabel sx={{ color: '#888' }}>Nom</InputLabel>
            <TextField
              value={filterNom}
              onChange={(e) => setFilterNom(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white' }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormControl fullWidth>
            <InputLabel sx={{ color: '#888' }}>E-mail</InputLabel>
            <TextField
               value={filterEmail}
               onChange={(e) => setFilterEmail(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white' }}
            />
          </FormControl>
        </Grid>
       
        
        <Grid item xs={12} sm={6} md={4}>
              <Button variant="contained" color="dark" fullWidth onClick={handleSearch}>
                Search
              </Button>
          </Grid>
      </Grid>
    </Paper>
        <div style={{ textAlign: "right", marginBottom: "20px" }}>
          <MKButton
            variant="contained"
            color="primary"
            onClick={handleAddNewCooperative}
            size="small"
          >
            {"Nouvelle cooperative"}
          </MKButton>
        </div>
        <Paper elevation={1} style={{ padding: "20px", marginTop: "20px" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {cooperatives.map((cooperative) => (
              <Card
                key={cooperative.id}
                variant="outlined"
                style={{
                  width: "30%",
                  marginBottom: "20px",
                  marginRight: "3%",
                }}
              >
                <CardContent>
                  <DefaultInfoCard
                    color="info"
                    icon="icon-name"
                    title={cooperative.nom}
                    description={
                      <>
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Reference"}:{" "}
                        </Typography>
                        {cooperative.reference}
                        <br />
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Nombre de sections"}:{" "}
                        </Typography>
                        {cooperative.sections.length}
                        <br />
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {t("couche.description")}:{" "}
                        </Typography>
                        {cooperative.description}
                        <br />
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Contacter"}:{" "}
                        </Typography>
                         <br />
                         <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Site Web"}:{" "}
                        </Typography>
                        {cooperative.siteWeb}
                         <br />
                         <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Telephone"}:{" "}
                        </Typography>
                        {cooperative.telephone}
                         <br />
                         <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Email"}:{" "}
                        </Typography>
                        {cooperative.email}
                         <br />
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {"Adresse"}:{" "}
                        </Typography>
                        {cooperative.adresse}
                      </>
                    }
                  />
                </CardContent>
                {false ? (
                  <CardActions style={{ justifyContent: "space-between" }}>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                      onClick={() => handleViewEdit("edit", cooperative.id)}
                    >
                      {t("actions.edit")}
                    </MKButton>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                      onClick={() => handleOpenDeleteDialog(cooperative.id)}
                    >
                      {t("actions.delete")}
                    </MKButton>
                  </CardActions>
                ) : (
                  <>
                    <CardActions style={{ justifyContent: "space-between" }}>
                      <MKButton
                        variant="contained"
                        color="info"
                        style={{ width: "25%" }}
                        onClick={() => handleViewEdit("view", cooperative.id)}
                      >
                        {t("actions.viewForm")}
                      </MKButton>
                      <MKButton
                        variant="contained"
                        color="info"
                        style={{ width: "25%" }}
                        onClick={() => handleViewEdit("edit", cooperative.id)}
                      >
                        {t("actions.edit")}
                      </MKButton>
                      <MKButton
                        variant="contained"
                        color="error"
                        style={{ width: "25%" }}
                        onClick={() => handleOpenDeleteDialog(cooperative.id)}
                      >
                        {t("actions.delete")}
                      </MKButton>
                      <MKButton
                        variant="contained"
                        color="info"
                        style={{ width: "25%" }}
                        onClick={() => dispatch(cooperativeActions.download(cooperative.id))}
                      >
                        {"Telecharger"}
                      </MKButton>
                    </CardActions>
                  </>
                )}
              </Card>
            ))}
          </div>
        </Paper>


      </BaseLayout>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("messages.confirmDelete")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleCloseDeleteDialog} color="primary">
            {t("actions.cancel")}
          </MKButton>
          <MKButton onClick={() => handleDelete(deleteId)} color="error" autoFocus>
            {t("actions.delete")}
          </MKButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Cooperative;
