import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Backdrop,
  CircularProgress,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography 
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { coucheActions } from "sagas/coucheSlice";
import BaseLayout from "layouts/sections/components/BaseLayout";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKButton from "components/MKButton";
import { COUCHE_FORM, COUCHE_VIEW_EDIT_FORM } from "router/constants";
import { COUCHE_MANAGE_PAGE } from "router/constants";

const Couche = () => {
  const { t } = useTranslation("translation");
  const { couches, isFetching } = useSelector((state) => state.couche);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    dispatch(coucheActions.list());
  }, []);

  const handleViewEdit = (operation, id) => {
    navigate(
      `${COUCHE_VIEW_EDIT_FORM.replace(":operation", operation).replace(
        ":id",
        id
      )}`
    );
  };

  const handleAddNewCouche = () => {
    navigate(COUCHE_FORM);
  };

  const handleDelete = (id) => {
    dispatch(
      coucheActions.delete({
        id: id,
        onSuccess: () => {
          dispatch(coucheActions.list());
          console.log(t("messages.coucheDeletedSuccessfully"));
        },
      })
    );
    handleCloseDeleteDialog();
  };

  return (
    <>
      {isFetching && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <BaseLayout
        breadcrumb={[{ label: t("labels.home") }, { label: t("menu.dashboard") }]}
      >
        <div style={{ textAlign: "right", marginBottom: "20px" }}>
          <MKButton
            variant="contained"
            color="primary"
            onClick={handleAddNewCouche}
            size="small"
          >
            {t("actions.newCouche")}
          </MKButton>
        </div>
        <Paper elevation={1} style={{ padding: "20px", marginTop: "20px" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {couches.map((couche) => (
              <Card
                key={couche.id}
                variant="outlined"
                style={{
                  width: "30%",
                  marginBottom: "20px",
                  marginRight: "3%",
                }}
              >
                <CardContent>
                  <DefaultInfoCard
                    color="info"
                    icon="icon-name"
                    title={couche.nomCode}
                    description={
                      <>
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {t("couche.caracteristiques")}:{" "}
                        </Typography>
                        {couche.caracteristiques}
                        <br />
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {t("couche.entreprise")}:{" "}
                        </Typography>
                        {couche.entreprise}
                        <br />
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {t("couche.description")}:{" "}
                        </Typography>
                        {couche.description}
                        <br />
                        <Typography variant="body2" component="span" style={{ fontWeight: '500' }}>
                          {t("couche.coucheNumber")}:{" "}
                        </Typography>
                        {couche.id}
                      </>
                    }
                  />
                </CardContent>
                {couche.typeDeCouche === "Type1" ? (
                  <CardActions style={{ justifyContent: "space-between" }}>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                      onClick={() => handleViewEdit("edit", couche.id)}
                    >
                      {t("actions.edit")}
                    </MKButton>
                    <MKButton
                      variant="contained"
                      color="error"
                      style={{ width: "45%" }}
                      onClick={() => handleOpenDeleteDialog(couche.id)}
                    >
                      {t("actions.delete")}
                    </MKButton>
                  </CardActions>
                ) : (
                  <>
                    <CardActions style={{ justifyContent: "space-between" }}>
                      <MKButton
                        variant="contained"
                        color="error"
                        style={{ width: "30%" }}
                        onClick={() => handleViewEdit("view", couche.id)}
                      >
                        {t("actions.viewForm")}
                      </MKButton>
                      <MKButton
                        variant="contained"
                        color="error"
                        style={{ width: "30%" }}
                        onClick={() => handleViewEdit("edit", couche.id)}
                      >
                        {t("actions.edit")}
                      </MKButton>
                      <MKButton
                        variant="contained"
                        color="error"
                        style={{ width: "30%" }}
                        onClick={() => handleOpenDeleteDialog(couche.id)}
                      >
                        {t("actions.delete")}
                      </MKButton>
                    </CardActions>
                    <CardActions
                      style={{ justifyContent: "center", marginTop: "10px" }}
                    >
                      <MKButton
                        variant="contained"
                        color="success"
                        style={{ width: "30%" }}
                      >
                        {t("actions.createActor")}
                      </MKButton>
                      <MKButton
                        variant="contained"
                        color="info"
                        style={{ width: "30%" }}
                      >
                        {t("actions.viewActor")}
                      </MKButton>
                      <MKButton
                        variant="contained"
                        color="warning"
                        style={{ width: "30%" }}
                      >
                        {t("actions.download")}
                      </MKButton>
                    </CardActions>
                  </>
                )}
              </Card>
            ))}
          </div>
        </Paper>
      </BaseLayout>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("messages.confirmDelete")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleCloseDeleteDialog} color="primary">
            {t("actions.cancel")}
          </MKButton>
          <MKButton onClick={() => handleDelete(deleteId)} color="error" autoFocus>
            {t("actions.delete")}
          </MKButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Couche;

