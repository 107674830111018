import config from "../config";
import instance from "./AxiosInterceptor";


export const loadData = () => {
    return instance.get(`${config.baseURL}/data`);
};
export const filterByCoop= async (id)=>{
    
    let response = await instance.get(`${config.baseURL}/cooperative/planteurs/${id}`);
    let planteurs = response?.data?.body;
    if(planteurs!=null && planteurs!= undefined){

        let hommes=0;
        let femmes=0;
        let plantations=0;
        let aCert=0;
        let bCert=0;
        let nbrEnfants=0;
        let nbrEnfantsActeNaissance=0;
        let nbrRisque=0;
        let nbrTravail=0;
        let nbrNonScolarise=0;
        let plantationTypes = {
            NoixDeCajou:0,
            Mais:0,
            Cacao:0,
            Banane:0
        }

        planteurs.map(p=>{

            if(p.genre=="MALE"){
                hommes=hommes+1;
            }
            else if(p.genre=="FEMALE"){
                femmes=femmes+1;
            }
            let arrayPlantations = p.plantations;

            plantations=plantations+p?.plantations.length;

            nbrEnfants = nbrEnfants+p.nbrEnfantsMoins18;
            nbrEnfantsActeNaissance = nbrEnfantsActeNaissance+p.nbrEnfantsActeNaissance;
            nbrNonScolarise =nbrNonScolarise+p.nbrEnfantsNonScolarises;
            nbrRisque=nbrRisque+p.nbrEnfantsRisqueTravailleurs;
            nbrTravail=nbrTravail+p.nbrEnfantsConfirmeTravailleurs;

            p.plantations.map(pl=>{
                if(pl.certification=="A"){
                    aCert=aCert+1;
                }
                else if(pl.certification=="B"){
                    bCert=bCert+1;
                }
                switch (pl.typePlante) {
                    case "Cacao":
                        plantationTypes.Cacao=plantationTypes.Cacao+1;
                        break;
                    case "Banane":
                        plantationTypes.Banane=plantationTypes.Banane+1;
                        break;
                    
                    case "Mais":
                        plantationTypes.Mais=plantationTypes.Mais+1;
                        break;
                    case "NoixDeCajou":
                        plantationTypes.NoixDeCajou=plantationTypes.NoixDeCajou+1;
                        break;

                    default:
                        break;
                }
            });




        });


        let data = {

            totalPlanteurs: planteurs.length,
            totalPMales:hommes,
            totalPFemales:femmes,
            totalPlantations: plantations,
            totalA:aCert,
            totalB:bCert,
            totalNbrEnfants:nbrEnfants,
            totalNbrEnfantsRisque:nbrRisque,
            totalNbrEnfantsTravail:nbrTravail,
            totalNbrEnfantsActeNaissance:nbrEnfantsActeNaissance,
            totalNbrEnfantsNonScolarises:nbrNonScolarise,
            plantationTypes:plantationTypes,

        };
        return data;
    }

}