import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { fetchPlanteurs, search, downloadPlanteurs, page } from "services/planteurService";
import { login } from "services/userService";

const planteurSlice = createModule({
  name: 'planteur',
  initialState: {
    planteurs: [],
    isFetching: false,
    error: null,
  },
  reducers: {
    fetchPlanteurs(state) {
      state.isFetching = true;
    },
    fetchPage(state) {
      state.isFetching = true;
    },
    addPlanteur(state, payload) {
      state.planteurs.push(payload);
    },
    fetchSuccess(state, payload) {
      state.isFetching = false;
      if (Array.isArray(payload))
        state.planteurs = payload;
      else {
        state.planteurs = [];
        state.planteurs.push(payload);
      }
    },
    fetchError: (state) => {
      state.error = "An error occured";
    },
    finishFetching(state) {
      state.isFetching = false;
    },
    searchPlanteurs(state, payload) {
      state.isFetching = true;
    },
    downloadExcel(state) {
      state.isFetching = true;
    }
  },
  sagas: (A) => ({
    *[A.fetchPlanteurs]() {
      try {
        const { data } = yield fetchPlanteurs();
        yield put(A.finishFetching());
        yield put(A.fetchSuccess(data?.body));
      } catch (error) {
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },

    *[A.addPlanteur]({ payload }) {
      try {
        const { data } = yield create(payload);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data);
      } catch (error) {
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.searchPlanteurs]({payload}){
      try {
        const { data } = yield search(payload);
        console.log(data?.body.content);
        yield put(A.finishFetching());
        yield put(A.fetchSuccess(data?.body.content));
      } catch (error) {
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.fetchPage]({payload}){
      try {
        const { data } = yield page(payload);
        yield put(A.finishFetching());
         yield put(A.fetchSuccess(data?.body));
      } catch (error) {
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }

    },
    *[A.downloadExcel](){

      try {
          yield downloadPlanteurs();
          yield put(A.finishFetching());
      } catch (e) {
          console.log(e); 
          yield put(A.finishFetching()); 
          yield put(A.fetchError()); 
      }
    }
  })
});


export default planteurSlice;
export const planteurActions = planteurSlice.actions;