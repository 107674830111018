

// @mui material components
import Icon from "@mui/material/Icon";


import { Navigate, createBrowserRouter } from "react-router-dom";
import { COUCHE_MANAGE_PAGE, COUCHE_VIEW_EDIT_FORM, COUCHE_FORM, DASHBOARD_PAGE, GEO_MANAGE_PAGE, MAIN_PAGE, NOT_FOUND_ERROR_PAGE_ROUTE, PLANTATION_MANAGE_PAGE, PLANTEUR_MANAGE_PAGE,  SIGN_IN_PAGE, USER_MANAGE_PAGE, WELCOME_PAGE, COOP_FORM,COOP_MANAGE_PAGE,COOP_VIEW_EDIT_FORM,SECTION_VIEW_EDIT_FORM,SECTION_FORM} from "./constants";
import SignInPage from "layouts/pages/authentication/sign-in";
import ProtectedRoute from "./ProtectedRoute";
import User from "pages/User";
import { ROLE_GEOMAP_ADMIN, ROLE_GEOMAP_GERANT } from "./roles";
import Plantation from "pages/Plantation";
import Couche from "pages/Couche";
import Cooperative from "pages/Cooperative";
import ErrorNotFoundPage from "pages/Errors/ErrorNotFoundPage";
import PresentationPage from "layouts/pages/presentation";
import Dashboard from "pages/Dashboard";
import Geolocalisation from "pages/Geolocalisation";
import Planteur from "pages/Planteur";
import AppMainContainer from "pages";
import FormCouche from "../pages/Couche/FormCouche";
import FormCoop from "../pages/Cooperative/FormCoop";
import FormSection from "../pages/Cooperative/FormSection";

const router = createBrowserRouter([
  { path: "/", element: <Navigate to={WELCOME_PAGE} replace /> },
  { path: "*", element: <Navigate to={NOT_FOUND_ERROR_PAGE_ROUTE} replace /> },
  { path: NOT_FOUND_ERROR_PAGE_ROUTE, element: ( <ErrorNotFoundPage /> ), exact: true, },
  { path: SIGN_IN_PAGE, element: <SignInPage />, exact: true },
  { path: WELCOME_PAGE, element: <PresentationPage />, exact: true },
  {
    path: MAIN_PAGE, element: <AppMainContainer />, children: [
      { path: USER_MANAGE_PAGE, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><User /></ProtectedRoute>, exact: true },
      { path: PLANTATION_MANAGE_PAGE, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><Plantation /></ProtectedRoute>, exact: true },
      { path: DASHBOARD_PAGE, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><Dashboard /></ProtectedRoute>, exact: true },
      { path: GEO_MANAGE_PAGE, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><Geolocalisation /></ProtectedRoute>, exact: true },
      { path: PLANTEUR_MANAGE_PAGE, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><Planteur /></ProtectedRoute>, exact: true },
      { path: COUCHE_MANAGE_PAGE, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><Couche /></ProtectedRoute>, exact: true },
      { path: COUCHE_FORM, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><FormCouche /></ProtectedRoute>, exact: true },
      { path: COUCHE_VIEW_EDIT_FORM, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><FormCouche /></ProtectedRoute>, exact: true },
      { path: COOP_MANAGE_PAGE, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><Cooperative /></ProtectedRoute>, exact: true },
      { path: COOP_FORM, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><FormCoop /></ProtectedRoute>, exact: true },
      { path: COOP_VIEW_EDIT_FORM, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><FormCoop /></ProtectedRoute>, exact: true },
      { path: SECTION_FORM, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><FormSection /></ProtectedRoute>, exact: true },
      { path: SECTION_VIEW_EDIT_FORM, element: <ProtectedRoute roles={[ROLE_GEOMAP_GERANT, ROLE_GEOMAP_ADMIN]}><FormSection /></ProtectedRoute>, exact: true },
    ]
  },
])

export default router;
