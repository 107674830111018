import { Backdrop, CircularProgress,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    Grid,
    Card,
    Container,
    CardActions,
    Button, } from "@mui/material";
import { styled } from '@mui/material/styles';
import { DataGrid } from "@mui/x-data-grid";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import { planteurActions } from "sagas/planteurSlice";
import {dataActions } from "sagas/dataSlice";
import Paper from '@mui/material/Paper';
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import axios from "axios";

const Planteur = () => { 
          const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
            backgroundColor: '#ffa5000d',
            boxShadow: 10, 
            marginTop:40,
            '& .MuiTablePagination-root': {
              display: 'none',
            },
              // Use Material-UI theme colors
          }));
        const { t } = useTranslation('translation')
        const columns = [
        { field: 'nom', headerAlign: 'center', align:'center', headerName: t('column.firstname'), width: 150 },
        { field: 'prenom', headerAlign: 'center',align:'center', headerName: t('column.lastname'), width: 150 },
        { field: 'contact', headerAlign: 'center',align:'center', headerName: "Telephone", width: 150 },
        { field: 'genre', headerAlign: 'center',align:'center', headerName: t('column.gender'), width: 150 },
        { field: 'age', headerAlign: 'center',align:'center', headerName: t('column.age'), width: 150 },
        { field: 'nbrPersonneCharger', headerAlign: 'center',align:'center', headerName: "Taille Ménage", width: 150 },
        { field: 'nbrEnfantsMoins18', headerAlign: 'center',align:'center', headerName: "Nombre enfants < 18", width: 200 },
        { field: 'nbrEnfantsActeNaissance', headerAlign: 'center',align:'center', headerName: "Enfants ayant acte de naissance", width: 300 },
        { field: 'nbrEnfantsScolarises', headerAlign: 'center',align:'center', headerName: "Enfants Scolarisés", width: 150 },
        { field: 'siNonScolarise', headerAlign: 'center',align:'center', headerName: "Si non scolariés", width: 150 },
        { field: 'nbrEnfantsConfirmeTravailleurs', headerAlign: 'center',align:'center', headerName: "Enfants en situation travail", width: 300 },
        { field: 'nbrEnfantsRisqueTravailleurs', headerAlign: 'center',align:'center', headerName: "Enfants en risque", width: 200 },
        { field: 'nationnalite', headerAlign: 'center',align:'center', headerName: t('column.nationality'), width: 150 },
        { field: 'datedenaissance', headerAlign: 'center',align:'center', headerName: t('column.dateofbirth'), width: 150 },
        { field: 'activitePrincipale', headerAlign: 'center',align:'center', headerName: t('column.mainactivity'), width: 150 },
        { field: 'activiteSecondaire', headerAlign: 'center',align:'center', headerName: t('column.secondaryactivity'), width: 150 },
        
        { field: 'anneesExperienceActivitePrincipale', headerAlign: t('column.yearsofexperience'),align:'center', headerName: 'annees experience', width: 150 },
        { field: 'certification', headerAlign: 'center',align:'center', headerName: t('column.certification'), width: 150 },
    ]
    const [filterNom, setFilterNom] = useState(''); // Filter nom state
    const [filterPrenom, setFilterPrenom] = useState(''); // Filter prenom state
    const [filterGenre, setFilterGenre] = useState(''); // Filter genre state
    const [filterAge, setFilterAge] = useState(''); // Filter age state
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage]=useState(0);
    const { planteurs, isFetching  } =  useSelector((state) => state.planteur);
    const {totalPlanteurs,totalPMales,totalPFemales}= useSelector((state)=> state.data);
    // const [paginationModel, setPaginationModel] = useState({
    //     pageSize: 20,
    //     page: 0,
    // });    
    
    const dispatch = useDispatch();

    const handleSearch = () => {
      const filters = {
        nom: filterNom,
        age: filterAge,
        genre: filterGenre,
        prenom: filterPrenom,
        
      };
      const dto = {
      criteria: filterObject(filters),
      page: 0,
      size: 200,
      };

      dispatch(planteurActions.searchPlanteurs(dto));

    };

    useEffect(() => {
      dispatch(dataActions.loadData());

    }, []);
    
   

    useEffect(() => {
      
      dispatch(planteurActions.fetchPage(page));

    }, [page]); 

    const showButton = planteurs.length == 20;
    const showButton1 = page > 0;
    
    let copyPlanteurs = planteurs.map(planteur=> ({
        ...planteur,
        genre: planteur.genre === "MALE" ? "Masculin" : planteur.genre === "FEMALE" ? "Féminin" : planteur.genre,
    })
    );



    return (
        <>
            {isFetching &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>}

            <BaseLayout breadcrumb={[{ label: "Acceuil" }, { label: t('menu.dashboard') }]}>

            <Card>
                <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <CardActions  style={{ justifyContent: "space-between" }}>
                <MKButton
                        variant="contained"
                        color="warning"
                        style={{ width: "50%" }}
                        onClick = {()=> dispatch(planteurActions.downloadExcel())}
                      >
                        {t("actions.download")+" Excel"}
                </MKButton>
                </CardActions>
                </Grid>
                </Grid>
                </Card>


            <Paper component="form" elevation={3} sx={{ padding: '16px', backgroundColor: '#f5f5f5', border: '1px solid #ddd' }}>
      <Typography variant="h6" gutterBottom sx={{ fontSize: '18px', fontWeight: 'bold' }}>
        Options Recherche
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
              <DefaultCounterCard count={totalPlanteurs} separator="," title={t('dashboard.bloc3')} description={`${totalPMales} Hommes ${totalPFemales} Femmes`} />
          </Grid>
      </Grid>
       <MKBox component="section" my={1} py={1}>
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
          
          <Grid item xs={12} md={2}>
            <TextField fullWidth variant="standard" label="Nom" value={filterNom} onChange={(e) => setFilterNom(e.target.value)} />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField fullWidth variant="standard" label="Prenom" value={filterPrenom} onChange={(e) => setFilterPrenom(e.target.value)} />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
          <FormControl fullWidth sx={{height:'100%'}}>
            <InputLabel id="genre" sx={{ color: '#888' }}>
                        Genre
            </InputLabel>
            <Select
               labelId="genre"
               value={filterGenre}
               onChange={(e) => setFilterGenre(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white', height:'100%' }}
            >
             <MenuItem value={"MALE"}>Homme</MenuItem>
             <MenuItem value={"FEMALE"}>Femme</MenuItem>
             <MenuItem value={""}>Non Specifie</MenuItem>

            </Select>
          </FormControl>
        </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth sx={{height:'100%'}} >
            <InputLabel id="age" sx={{ color: '#888' }}>
                              Age
            </InputLabel>
            <Select
               labelId="Age"
               value={filterAge}
               onChange={(e) => setFilterAge(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white', height:'100%' }}
            >
            <MenuItem value={"18_25"}>18 - 25</MenuItem>
             <MenuItem value={"25_35"}>25 - 35</MenuItem>
             <MenuItem value={"35_45"}>35 - 45</MenuItem>
             <MenuItem value={"45_55"}>45 - 55</MenuItem>
             <MenuItem value={"55_65"}>55 - 65</MenuItem>
             <MenuItem value={"65_75"}>65 - 75</MenuItem>
             <MenuItem value={"75_85"}>75 - 85</MenuItem>
             <MenuItem value={""}>Non Specifie</MenuItem>
            </Select>
          </FormControl>
          </Grid>
         
          <Grid item xs={12} md={4}>
            <Button fullWidth variant="contained" onClick={handleSearch} color="info" size="large">
              Lancer la recherche
            </Button>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
    </Paper>

                {(!planteurs || planteurs?.length == 0) ? <div>{t('messages.nodata')}</div> :
                    <>                          

                        <div style={{ height: 700, width: '110%' ,alignItems:'center' }}>
                            <StyledDataGrid
                                rows={copyPlanteurs}
                                columns={columns}
                                getRowId={(r) => {
                                    if (r === null) { return null }
                                    return r.id
                                }}
                                sx={{
                                  borderColor: '#000000',
                                  boxShadow: 10,                                  
                                }}
                                // paginationModel={page}
                                // onPaginationModelChange={setPage}
                            /> 
                           {showButton1 && <Button color="primary" onClick={() => { setPage(page -1);console.log('pagea',page); }}>
                           page precédente
                            </Button>}
                           {showButton && <Button color="primary" onClick={() => { setPage(page +1);console.log('pagea',page); }}>
                           page suivante
                            </Button>}
                        </div>
                    </>}

            </BaseLayout>
           </>
    
    )
};

  const filterObject = (obj) => {
    const filtered = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        filtered[key] = obj[key];
      }
    });
    return filtered;
  };

export default Planteur;