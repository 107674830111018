import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { loadData, filterByCoop } from "services/dataService";
import { login } from "services/userService";


const dataSlice = createModule({
  name: "data",
  initialState: {
    totalUsers: 0,
    totalPlanteurs: 0,
    totalPMales:0,
    totalPFemales:0,
    totalPlantations: 0,
    totalA:0,
    totalB:0,
    totalCoops:0,
    totalNbrEnfants:0,
    totalNbrEnfantsRisque:0,
    totalNbrEnfantsTravail:0,
    totalNbrEnfantsActeNaissance:0,
    totalNbrEnfantsNonScolarises:0,
    plantationTypes:null,
    isFetching: false,
    error: null,
  },
  reducers: {
    loadData: (state) => {
      state.isFetching = true;
    },
    coop: (state)=>{
      state.isFetching = true;
    },
    
    fetchSuccess: (state, payload) => {
      state.totalUsers = payload?.totalUsers || state.totalUsers;
      state.totalPlanteurs = payload?.totalPlanteurs;
      state.totalPMales = payload?.totalPMales;
      state.totalPFemales = payload?.totalPFemales;
      state.totalPlantations = payload?.totalPlantations;    
      state.plantationTypes = payload?.plantationTypes;
      state.totalA=payload?.totalACertificationPlantations;
      state.totalB=payload?.totalBCertificationPlantations;
      state.totalCoops=payload?.totalCoops || 0;
      state.totalNbrEnfants=payload?.totalNbrEnfants;
      state.totalNbrEnfantsRisque=payload?.totalNbrEnfantsRisque;
      state.totalNbrEnfantsTravail=payload?.totalNbrEnfantsTravail;
      state.totalNbrEnfantsNonScolarises=payload?.totalNbrEnfantsNonScolarises;
      state.totalNbrEnfantsActeNaissance=payload?.totalNbrEnfantsActeNaissance;

    },
    
    finishFetching: (state) => {
      state.isFetching = false;
    },
    fetchError: (state) => {
      state.error = "An error occured";
    },
  },
  sagas: (A) => ({
    *[A.loadData]() {
      try {
        const { data } = yield loadData();
        yield put(A.finishFetching());
        yield put(A.fetchSuccess(data?.body));
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.coop]({payload}){
      if(payload != 0 && payload !=null && payload!=undefined ){
              try {
                const data = yield filterByCoop(payload);
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data));
              } catch (e) {
                  console.log(e);
                  yield put(A.finishFetching());
                  yield put(A.fetchError());
                }
      }

    }
    
  }),
});

export default dataSlice;
export const dataActions = dataSlice.actions;