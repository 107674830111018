import instance from "./AxiosInterceptor";

export const create = async (data) => {
    console.log(data)
    try {
        const response = await instance.post(`/cooperative/createC`, data);
        console.log('Response:', response.data); // Log the response data
        return response.data; // Return the response data if needed
    } catch (error) {
        console.error('Error creating cooperative:', error);
        throw error; // Throw the error to handle it in the caller function
    }
};

export const list = () => {
    return instance.get(`/cooperative/list`);
};

export const searchCoop = async (payload) => {
     try {
        const response = await instance.post(`/cooperative/searchC`, payload);
        console.log('Response:', response); // Log the response data
        return response; // Return the response data if needed
    } catch (error) {
        console.error('Error while searching :', error);
        throw error; // Throw the error to handle it in the caller function
    }
}

export const update = (id, data) => {
    return instance.put(`/cooperative/updateC/${id}`, data);
};

export const deleteCooperative = (id) => {
    return instance.delete(`/cooperative/deleteC/${id}`);
};

export const get = async (id) => {
    try {
       return await instance.get(`/cooperative/${id}`);  
    } catch (error) {
        console.error('Error fetching cooperative:', error);
        throw error; 
    }
};

export const downloadExcel = async (id) => {
  try {
    const response = await instance.get(`/cooperative/export/${id}`, {
      responseType: 'blob', // Important to handle binary data
    });

    // Create a link element, set the download attribute, and trigger a click event
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'cooperative.xlsx'); // You can set the file name dynamically if needed
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};

export default { create, list, update, deleteCooperative, get, downloadExcel };