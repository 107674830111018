

// @mui material components
import Icon from "@mui/material/Icon";

import { COUCHE_MANAGE_PAGE, DASHBOARD_PAGE, GEO_MANAGE_PAGE, PLANTATION_MANAGE_PAGE, PLANTEUR_MANAGE_PAGE, USER_MANAGE_PAGE, WELCOME_PAGE,COOP_FORM,COOP_MANAGE_PAGE,COOP_VIEW_EDIT_FORM} from "./constants";
import { ArrowBack, ArrowDownward, Business, Dashboard, Filter, FindReplaceTwoTone, Help, Map, Person2, PersonOffOutlined, Schema, TravelExplore, VerifiedUser, ZoomIn } from "@mui/icons-material";

const routes = [
  {
    name: "tableau de bord",
    icon: <Dashboard/>,
    route: DASHBOARD_PAGE,
  },
  {
    name: "plantation",
    icon: <TravelExplore/>,
    route: PLANTATION_MANAGE_PAGE,
  },
  // {
  //   name: "hiérarchisation",
  //   icon: <ArrowDownward/>,
  //   route: PLANTATION_MANAGE_PAGE,
  // },
  {
    name: "Géolocalisation",
    icon: <FindReplaceTwoTone/>,
    route: GEO_MANAGE_PAGE,
  },
  {
    name: "Planteur",
    icon: <FindReplaceTwoTone/>,
    route: PLANTEUR_MANAGE_PAGE,
  },
  // {
  //   name: "Profil Entreprise",
  //   icon: <Business/>,
  //   route: PLANTATION_MANAGE_PAGE,
  // },
  // {
  //   name: "Profil Utilisateur",
  //   icon: <Person2/>,
  //   route: USER_MANAGE_PAGE,
  // },
  
  {
    name:"Cooperatives",
    icon:<Schema/>,
    route: COOP_MANAGE_PAGE,
  },
];

export default routes;
