import { Outlet } from "react-router-dom";
import { AxiosInterceptor } from "services/AxiosInterceptor";

const AppMainContainer = () => {

    return (
        <AxiosInterceptor>
            <Outlet/>
        </AxiosInterceptor>
    )
}

export default AppMainContainer;