import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  Snackbar,
  Alert,
  FormHelperText,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography
} from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cooperativeActions } from "sagas/cooperativeSlice";
import {sectionActions} from "sagas/sectionSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { COOP_MANAGE_PAGE, SECTION_VIEW_EDIT_FORM, SECTION_FORM } from "router/constants";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKButton from "components/MKButton";

const FormSection = () => {
  const { cooperativeId,id, operation } = useParams();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { isFetching } = useSelector((state) => state.data);
  const [open, setOpen] = useState(false);

  console.log(cooperativeId,id,operation);

  useEffect(() => {
    if (cooperativeId) {
        let payload = {
            id: cooperativeId,
            onSuccess: (data) => {
              let section1=null;
              data.sections.map((section)=>{if(section.id==id){section1=section} });
              console.log(section1);
                setData(section1);
            }
        }
        dispatch(cooperativeActions.get(payload))
    }
  }, [dispatch, cooperativeId,id]);
  const handleClose = () => {
    setOpen(false);
    navigate(COOP_MANAGE_PAGE)
  };

  const initialValues = {
    nom: data?.nom || "",
    code:data?.code || "",
    adresse:data?.adresse || "",
    cooperative:cooperativeId || "",
  };

  const validationSchema = Yup.object().shape({
    nom: Yup.string().required(),
    code: Yup.string().required(),
    adresse: Yup.string().required(),  
    });

  const onSubmit = (values) => {
    console.log(values);
    try {
      const payload = {
        id:id,
        data: {
          nom:values.nom,
          adresse:values.adresse,
          code:values.code,
          cooperativeId: values.cooperative,
        },
        onSuccess: () => {
            setOpen(true); 
        },
      };
      console.log(payload);
      if (operation === 'edit') {
        dispatch(sectionActions.update(payload));
        console.log('Cooperative updated successfully');
      } else {
        dispatch(sectionActions.create(payload));
        console.log('Cooperative added successfully');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema,
    onSubmit,
  });

  return (
   <>
    {isFetching && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <BaseLayout
        breadcrumb={[{ label: "Accueil" }, { label: t("menu.dashboard") }]}
      >
        <Paper sx={{ p: 2, mt: 2, boxShadow: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
             
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="nom"
                  name="nom"
                  label={"Nom"}
                  value={formik.values.nom}
                  disabled={operation === "view"}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nom && Boolean(formik.errors.nom)
                  }
                  helperText={formik.touched.nom && formik.errors.nom}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  label={"Code"}
                  value={formik.values.code}
                  disabled={operation === "view"}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.code &&
                    Boolean(formik.errors.code)
                  }
                  helperText={
                    formik.touched.code && formik.errors.code
                  }
                />
              </Grid>

                <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="adresse"
                  name="adresse"
                  label={"Adresse"}
                  disabled={operation === "view"}
                  value={formik.values.adresse}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.adresse &&
                    Boolean(formik.errors.adresse)
                  }
                  helperText={
                    formik.touched.adresse &&
                    formik.errors.adresse
                  }
                />
              </Grid>
            
              {operation !== "view" && (
                <Grid item xs={12}>
                    <MKButton type="submit" variant="contained" color="info">
                      {operation === "edit" ? t("actions.edit") : t("actions.save")}
                    </MKButton>
                </Grid>
                )}
            </Grid>
          </form> 
        </Paper>
      </BaseLayout>
      <Snackbar open={open} autoHideDuration={1100} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled">
          {t('messages.success')}
        </Alert>
      </Snackbar>
   </>
  )
}

export default FormSection;