import { put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { fetchPlantation,download, downloadKml, downloadPlantations, search, page} from "services/plantationService";

const plantationSlice = createModule({
    name: 'plantation',
    initialState: {
        plantations: [],
        isFetching: false,
        error: null,
    },
    reducers: {
        fetchPlantations: (state) => {
            state.isFetching = true;
        },
        fetchSuccess: (state, payload) => {
            if (Array.isArray(payload))
                state.plantations = payload;
            else {
                state.plantations = [];
                state.plantations.push(payload);
            }
        },
        finishFetching: (state) => {
            state.isFetching = false;
        },
        fetchError: (state) => {
            state.error = "An error occured";
        },
        add: (state, payload) => {
            //state.products.push(payload)
            //state.filteredProds.push(payload)

        },
        edit: (state, payload) => {

            // state.products[payload.index] = payload.editedP


        },
        search: (state, payload) => {
            // state.filteredProds = state.products.filter((x)=>{return x.label.includes(payload)})
        },
        delete: (state, payload) => {
            //state.products.splice(payload, 1)
        },
        downloadGPX:(state,payload) => {
            state.isFetching = true;

        },
        downloadKML:(state,payload) => {
            state.isFetching = true;

        },
        downloadExcel:(state) =>{
            state.isFetching = true;
        },
        page:(state)=>{
            state.isFetching = true;
        },
    },
    sagas: (A) => ({
        *[A.fetchPlantations]() {
            try {
                const { data } = yield fetchPlantation();
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data?.body));
            }
            catch (e) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.add]({ payload }) {
            //Post service

        },
        *[A.search]({ payload }) {
            try {
                const { data } = yield search(payload);
                yield put(A.finishFetching());
                console.log(data?.body.content);
                yield put(A.fetchSuccess(data?.body.content));
            } catch (error) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }

        },
        *[A.page]({payload}){
             try {
                const { data } = yield page(payload);
                yield put(A.finishFetching());
                console.log(data?.body);
                yield put(A.fetchSuccess(data?.body));
            } catch (error) {
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.delete]({ payload }) {

        },
        *[A.edit]({ payload }) {
        },
        *[A.downloadGPX]({payload}){

            try{
                const response = yield download(payload.token);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
            catch(e){
                yield put(A.finishFetching());
                yield put(A.fetchError());

            }

        },
        *[A.downloadKML]({payload}){

            try{
                const response = yield downloadKml(payload.token);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
            catch(e){
                yield put(A.finishFetching());
                yield put(A.fetchError());

            }

        },
        *[A.downloadExcel](){

      try {
          yield downloadPlantations();
          yield put(A.finishFetching());
      } catch (e) {
          console.log(e); 
          yield put(A.finishFetching()); 
          yield put(A.fetchError()); 
      }
    }
    })
})

export const plantationActions = plantationSlice.actions;
export default plantationSlice;