import config from "config";
import instance from "./AxiosInterceptor";
import axios from "axios";

export const fetchPlantation = () => {
    const res = instance.get(`${config.baseURL}/plantation/list`)
    return res
};

export const search=(payload) => {
    return instance.post(`/plantation/searchPl`,JSON.stringify(payload));
};

export const page = (payload) => {
    return instance.get(`/plantation/page/${payload}`);
};

export const download = async (token) => {
    const res = await axios.get(`${config.baseURL}/plantation/gpx`,{
        responseType:'blob',
     headers: {
                      'Content-Type': 'application/zip',
                      'Authorization': `Bearer ${token}`,
                    },
        }
    );
    const blob = new Blob([res.data], { type: 'application/zip' });

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Result_files.zip');

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up resources
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  
};


export const downloadKml = async (token) => {
    const res = await axios.get(`${config.baseURL}/plantation/kml`,{
        responseType:'blob',
     headers: {
                      'Content-Type': 'application/zip',
                      'Authorization': `Bearer ${token}`,
                    },
        }
    );
    const blob = new Blob([res.data], { type: 'application/zip' });

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Result_files.zip');

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up resources
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  
};

export const downloadPlantations = async () => {
  try {
    const response = await instance.get(`/plantation/export`, {
      responseType: 'blob', // Important to handle binary data
    });

    // Create a link element, set the download attribute, and trigger a click event
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Plantations.xlsx'); // You can set the file name dynamically if needed
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};


