import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { login } from "services/userService";

const userSlice = createModule({
  name: "user",
  initialState: {
    token: null,
    authorities: [],
    isFetching: false,
    error: null,
  },
  reducers: {
    setAuthorities: (state, payload) => {
      state.authorities = payload.roles;
      state.token = payload.token;
    },
    login: (state) => {
      state.isFetching = true;
    },
    setAuthorities: (state, payload) => {
      state.authorities = payload.authorities;
      state.token = payload.token;
    },
    fetchSuccess: (state, payload) => {
      state.agentsList = payload.body;
      // state.total = payload.body.totalElements;
    },
    fetchSuccessCurrent: (state, payload) => {
      state.agentsList = [payload.body];
      // state.total = payload.body.totalElements;
    },
    finishFetching: (state) => {
      state.isFetching = false;
    },
    fetchError: (state) => {
      state.error = "An error occured";
    },
  },
  sagas: (A) => ({
    *[A.login]({ payload }) {
      try {
        const { data } = yield login(payload.data);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    
  }),
});

export default userSlice;
export const userActions = userSlice.actions;