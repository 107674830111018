import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  Snackbar,
  Alert,
  FormHelperText
} from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { coucheActions } from "sagas/coucheSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { COUCHE_MANAGE_PAGE } from "router/constants";
import MKButton from "components/MKButton";

const FormCouche = () => {
  const { id, operation } = useParams();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { isFetching } = useSelector((state) => state.data);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (id) {
        let payload = {
            id: id,
            onSuccess: (data) => {
                setData(data);
            }
        }
        dispatch(coucheActions.get(payload))
    }
  }, [dispatch, id]);

  const handleClose = () => {
    setOpen(false);
    navigate(COUCHE_MANAGE_PAGE)
  };

  const initialValues = {
    entreprise: data?.entreprise || "",
    nomCode: data?.nomCode || "",
    description: data?.description || "",
    caracteristiques: data?.caracteristiques || "",
    typeDeCouche: data?.typeDeCouche || "",
    acteurs: data?.acteurs || [],
  };

  const validationSchema = Yup.object().shape({
    entreprise: Yup.string().required(t('couche.validation.required')),
    nomCode: Yup.string().required(t('couche.validation.required')),
    description: Yup.string().required(t('couche.validation.required')),
    caracteristiques: Yup.string().required(t('couche.validation.required')),
    typeDeCouche: Yup.string().required(t('couche.validation.required')),
  });

  const onSubmit = (values) => {
    try {
      const payload = {
        id:id,
        data: values,
        onSuccess: () => {
            setOpen(true); 
        },
      };
  
      if (operation === 'edit') {
        dispatch(coucheActions.update(payload));
        console.log('Couche updated successfully');
      } else {
        dispatch(coucheActions.create(payload));
        console.log('Couche added successfully');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema,
    onSubmit,
  });

  return (
   <>
    {isFetching && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <BaseLayout
        breadcrumb={[{ label: "Accueil" }, { label: t("menu.dashboard") }]}
      >
        <Paper sx={{ p: 2, mt: 2, boxShadow: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="entreprise"
                  name="entreprise"
                  label={t("couche.entreprise")}
                  value={formik.values.entreprise}
                  disabled={operation === "view"}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.entreprise &&
                    Boolean(formik.errors.entreprise)
                  }
                  helperText={
                    formik.touched.entreprise && formik.errors.entreprise
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="nomCode"
                  name="nomCode"
                  label={t("couche.nomCode")}
                  value={formik.values.nomCode}
                  disabled={operation === "view"}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nomCode && Boolean(formik.errors.nomCode)
                  }
                  helperText={formik.touched.nomCode && formik.errors.nomCode}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label={t("couche.description")}
                  value={formik.values.description}
                  disabled={operation === "view"}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="caracteristiques"
                  name="caracteristiques"
                  label={t("couche.caracteristiques")}
                  disabled={operation === "view"}
                  value={formik.values.caracteristiques}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.caracteristiques &&
                    Boolean(formik.errors.caracteristiques)
                  }
                  helperText={
                    formik.touched.caracteristiques &&
                    formik.errors.caracteristiques
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={
                    formik.touched.typeDeCouche &&
                    Boolean(formik.errors.typeDeCouche)
                  }
                >
                  <InputLabel id="typeDeCouche-label">
                    {t("couche.typeDeCouche")}
                  </InputLabel>
                  <Select
                    labelId="typeDeCouche-label"
                    id="typeDeCouche"
                    name="typeDeCouche"
                    value={formik.values.typeDeCouche || ''}
                    disabled={operation === "view"}
                    onChange={formik.handleChange}
                    label={t("couche.typeDeCouche")}
                    sx={{ p: 1.4 }} 
                    >
                    <MenuItem value="">{t("couche.selectType")}</MenuItem>
                    <MenuItem value="Type1">Principale</MenuItem>
                    <MenuItem value="Type2">Normal</MenuItem>
                    </Select>
                  {formik.touched.typeDeCouche &&
                    formik.errors.typeDeCouche && (
                      <FormHelperText>
                        {formik.errors.typeDeCouche}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              {operation !== "view" && (
                <Grid item xs={12}>
                    <MKButton type="submit" variant="contained" color="info">
                      {operation === "edit" ? t("actions.edit") : t("actions.save")}
                    </MKButton>
                </Grid>
                )}
            </Grid>
          </form>
        </Paper>
      </BaseLayout>
      <Snackbar open={open} autoHideDuration={1100} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled">
          {t('messages.success')}
        </Alert>
      </Snackbar>
   </>
  )
}

export default FormCouche;

