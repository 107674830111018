import instance from "./AxiosInterceptor";
import config from "../config";


export const create = (planteurDto) => {
    return instance.post(`/planteur/createP`, JSON.stringify(planteurDto));
};

export const update = (planteurDto) => {
    return instance.put(`/planteur/updateP`, JSON.stringify(planteurDto));
};

export const findById = (id) => {
    return instance.get(`/planteur/${id}`);
};

export const fetchPlanteurs = async () => {
    console.log("here");
    return instance.get(`${config.baseURL}/planteur/list`);
};
export const deleteP = (id) => {
    return instance.delete(`/planteur/deleteP/${id}`);
};
export const search=(payload) => {
    return instance.post(`/planteur/searchP`,JSON.stringify(payload));
};

export const page = (payload) => {
    return instance.get(`/planteur/page/${payload}`);
}

export const downloadPlanteurs = async () => {
  try {
    const response = await instance.get(`/planteur/export`, {
      responseType: 'blob', // Important to handle binary data
    });

    // Create a link element, set the download attribute, and trigger a click event
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Planteurs.xlsx'); // You can set the file name dynamically if needed
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};