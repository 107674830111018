import { Backdrop, CircularProgress,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    Grid,
    Button, } from "@mui/material";
import { styled } from '@mui/material/styles';
import { DataGrid } from "@mui/x-data-grid";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { planteurActions } from "sagas/planteurSlice";
import Paper from '@mui/material/Paper';
import axios from "axios";

const PlanteurSearchResult = () => { 
          const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
            backgroundColor: '#ffa5000d',
            boxShadow: 10, 
            marginTop:40,
            '& .MuiTablePagination-root': {
              display: 'none',
            },
              // Use Material-UI theme colors
          }));
        const { t } = useTranslation('translation')
        const columns = [
        { field: 'nom', headerAlign: 'center', align:'center', headerName: t('column.firstname'), width: 150 },
        { field: 'prenom', headerAlign: 'center',align:'center', headerName: t('column.lastname'), width: 150 },
        { field: 'contact', headerAlign: 'center',align:'center', headerName: "Telephone", width: 150 },
        { field: 'genre', headerAlign: 'center',align:'center', headerName: t('column.gender'), width: 150 },
        { field: 'age', headerAlign: 'center',align:'center', headerName: t('column.age'), width: 150 },
        { field: 'nbrPersonneCharger', headerAlign: 'center',align:'center', headerName: "Taille Ménage", width: 150 },
        { field: 'nbrEnfantsMoins18', headerAlign: 'center',align:'center', headerName: "Nombre enfants < 18", width: 200 },
        { field: 'nbrEnfantsActeNaissance', headerAlign: 'center',align:'center', headerName: "Enfants ayant acte de naissance", width: 300 },
        { field: 'nbrEnfantsScolarises', headerAlign: 'center',align:'center', headerName: "Enfants Scolarisés", width: 150 },
        { field: 'siNonScolarise', headerAlign: 'center',align:'center', headerName: "Si non scolariés", width: 150 },
        { field: 'nbrEnfantsConfirmeTravailleurs', headerAlign: 'center',align:'center', headerName: "Enfants en situation travail", width: 300 },
        { field: 'nbrEnfantsRisqueTravailleurs', headerAlign: 'center',align:'center', headerName: "Enfants en risque", width: 200 },
        { field: 'nationnalite', headerAlign: 'center',align:'center', headerName: t('column.nationality'), width: 150 },
        { field: 'datedenaissance', headerAlign: 'center',align:'center', headerName: t('column.dateofbirth'), width: 150 },
        { field: 'activitePrincipale', headerAlign: 'center',align:'center', headerName: t('column.mainactivity'), width: 150 },
        { field: 'activiteSecondaire', headerAlign: 'center',align:'center', headerName: t('column.secondaryactivity'), width: 150 },
        
        { field: 'anneesExperienceActivitePrincipale', headerAlign: t('column.yearsofexperience'),align:'center', headerName: 'annees experience', width: 150 },
        { field: 'certification', headerAlign: 'center',align:'center', headerName: t('column.certification'), width: 150 },
    ]
    const [filterNom, setFilterNom] = useState(''); // Filter nom state
    const [filterPrenom, setFilterPrenom] = useState(''); // Filter prenom state
    const [filterGenre, setFilterGenre] = useState(''); // Filter genre state
    const [filterAge, setFilterAge] = useState(''); // Filter age state
    const [searchTerm, setSearchTerm] = useState('');
    const { planteurs, isFetching  } =  useSelector((state) => state.planteur);


    console.log(planteurs);

  
    





    return (
        <>
            {isFetching &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>}

           


                {(!planteurs || planteurs?.length == 0) ? <div>{t('messages.nodata')}</div> :
                    <>                          

                        <div style={{ height: 700, width: '110%' ,alignItems:'center' }}>
                            <StyledDataGrid
                                rows={planteurs}
                                columns={columns}
                                getRowId={(r) => {
                                    if (r === null) { return null }
                                    return r.id
                                }}
                                sx={{
                                  borderColor: '#000000',
                                  boxShadow: 10,                                  
                                }}
                               
                            /> 
                           
                        </div>
                    </>}

           

        </>
    )
}
export default PlanteurSearchResult;