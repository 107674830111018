import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: {
    translation: {
      login: {
        title:"SE CONNECTER",
        username:"Nom utilisateur",
        password:"Mot de passe"
      },
      actions:{
        login:"Login",
        logout:"Logout",
        newCouche: "Nouvelle Couche",
        edit: "Modifier",
        save: "Enregistrer",
        delete: "Supprimer",
        viewForm: "Voir Formulaire",
        createActor: "Créer Acteur",
        viewActor: "Voir Acteur",
        download: "Télécharger",
        cancel: "Annuler"

      },
      menu:{
        dashboard:"Tableau de bord",
        plantations:"Plantations",
        hierarchie:"Hiérarchie",
        geoloc:"Géo-Localisation",
        profil:"Profil Entreprise",
        users:"Liste des Personnes",
        couche:"Couches"
      },
      dashboard:{
        bloc1:"Nombre d'utilisateurs",
        bloc2:"Nombre de plantations",
        bloc3:"Nombre de planteurs",
        bloc4:"Superficie (Ha)"
      },
      messages:{
        nodata:"Aucune donnée",
        success:"Succès"
      },
      column:{
        firstname:"Nom",
        lastname:"Prénom",
        mainactivity:"Activite principale",
        secondaryactivity:"Activite secondaire",
        age:"Age",
        yearsofexperience:"Années d'expérience",
        gender:"Genre",
        certification:"Attestation",
        dateofbirth:"Date de naissance",
        nationality:"Nationalite",
        contact:"Contact",
      },
       couche:{
        entreprise: "Entreprise",
        nomCode: "Nom Code",
        description: "Description",
        caracteristiques: "Caractéristiques",
        typeDeCouche: "Type de Couche",
        selectType: "Sélectionner un type",
        coucheNumber: "Numéro de Couche"
      },
      search:{
        text:"Search planteurs "
      },
    }
  },
  en: {
    translation: {
      login: {
        title:"Sign in"
      },
      column:{
        firstname:"Name",
        lastname:"lastname",
        mainactivity:"Main activity",
        secondaryactivity:"Secondary activity",
        age:"Age",
        yearsofexperience:"years of experience",
        gender:"Gender",
        certification:"Certification",
        dateofbirth:"Date of birth",
        nationality:"Nationality",
        contact:"Contact",

      },
      search:{
        text:"Search planteurs "
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
