import createSagaMiddleware from "@redux-saga/core";
import { rootReducer, rootSaga } from "saga-slice";
import { configureStore } from "@reduxjs/toolkit";
import userSlice from "sagas/userSlice";
import dataSlice from "sagas/dataSlice";
import plantationSlice from "sagas/plantationSlice";
import planteurSlice from "sagas/planteurSlice";
import coucheSlice from "sagas/coucheSlice";
import cooperativeSlice from "sagas/cooperativeSlice";
import sectionSlice from "sagas/sectionSlice";
import regionSlice from "sagas/regionSlice";


const modules = [userSlice,dataSlice,plantationSlice, planteurSlice,coucheSlice,cooperativeSlice, sectionSlice, regionSlice];

const sagaMiddleware = createSagaMiddleware();

const appReducer = rootReducer(modules);

const store = configureStore({
    reducer: appReducer,
    middleware: [sagaMiddleware],
    devTools:false
});

sagaMiddleware.run(rootSaga(modules));

export default store;
