import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({roles, redirectPath = '/forbidden', children}) => {
  const {token, authorities} = useSelector((state) => state.user);
  
  const hasAnyRole = (rolesIn) => {
    
    if (rolesIn.length === 0) {
      return true;
    }
    
    if(!token) {
      return false;
    }

    const found = rolesIn.some(r=> authorities.indexOf(r) >= 0);
    return found;
  }

  if (!hasAnyRole(roles)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
