

import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import * as yup from 'yup';

import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import SimpleFooter from "examples/Footers/SimpleFooter";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { DASHBOARD_PAGE } from "router/constants";
import { userActions } from "sagas/userSlice";
import { jwtDecode } from "jwt-decode";
import { isTokenExpired } from "services/AxiosInterceptor";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";

function SignInBasic() {
  const [rememberMe, setRememberMe] = useState(false);
  const {isFetching} = useSelector(state => state.user);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = yup.object().shape({
    username: yup.string().trim().required("Champ obligatoire"),
    password: yup.string().trim().required("Champ obligatoire"),
  });

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: login,
    onSubmit: (values) => {
      let payload = {
        data: values,
        onSuccess: ({ token }) => {
          if (!Boolean(token)) {
            //TODO
          }
          sessionStorage.setItem("token", token);
          const decodedToken = jwtDecode(token);
          if (isTokenExpired(decodedToken)) {
            //TODO
          } else {
            if (!!token) {
              dispatch(userActions.setAuthorities({ authorities: decodedToken.roles, token: token }));
              navigate(DASHBOARD_PAGE);
            }
          }
        }
      }
      dispatch(userActions.login(payload));
    }
  });

  return (
    <>
       {isFetching && <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}>

        <CircularProgress color="inherit" />
      </Backdrop>}
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {t('login.title')}
                </MKTypography>
                <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <FacebookIcon color="inherit" />
                    </MKTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <GoogleIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                </Grid>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                  <MKBox>
                    <MKBox mb={2}>
                      <MKInput label={t('login.username')} fullWidth {...formik.getFieldProps('username')} />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput type="password" label={t('login.password')} fullWidth {...formik.getFieldProps('password')} />
                    </MKBox>
                    <MKBox display="flex" alignItems="center" ml={-1}>
                      <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                      <MKTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={handleSetRememberMe}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Remember me
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={4} mb={1}>
                      <MKButton type="submit" variant="gradient" color="success" fullWidth>
                        {t('actions.login')}
                      </MKButton>
                    </MKBox>
                    {/* <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MKTypography
                        component={Link}
                        to="/authentication/sign-up/cover"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign up
                      </MKTypography>
                    </MKTypography>
                  </MKBox> */}
                  </MKBox>
                </form>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>

      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default SignInBasic;
