import { Backdrop, CircularProgress,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    Grid,
    Card,
    CardActions,
    Button, } from "@mui/material";
    import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import { styled } from '@mui/material/styles';
import { DataGrid } from "@mui/x-data-grid";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { plantationActions } from "sagas/plantationSlice";
import Paper from '@mui/material/Paper';
import { dataActions } from "sagas/dataSlice";
import MKButton from "components/MKButton";
import axios from "axios";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { APIProvider, AdvancedMarker, Map, Marker, limitTiltRange, useMap, Pin } from "@vis.gl/react-google-maps";
const Plantation = () => { 
          const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
            backgroundColor: '#ffa5000d',
            boxShadow: 10, 
            marginTop:40,
            '& .MuiTablePagination-root': {
              display: 'none',
            },
              // Use Material-UI theme colors
          }));
        const { t } = useTranslation('translation');
        const { totalUsers, totalPlanteurs, totalPMales, totalPFemales, totalPlantations, plantationTypes,totalA,totalB,totalCoops,totalNbrEnfants,totalNbrEnfantsRisque,totalNbrEnfantsTravail,totalNbrEnfantsActeNaissance, totalNbrEnfantsNonScolarises} = useSelector(state => state.data);
        const columns = [
        { field: 'codePlantation', headerAlign: 'center', align:'center', headerName: "Code", width: 150 },
        { field: 'plantationAge', headerAlign: 'center',align:'center', headerName:"Age" , width: 50 },        
        { field: 'anneeCreation', headerAlign: 'center',align:'center', headerName:'Annee creation', width: 150 },
        { field: 'typePaiement', headerAlign: 'center',align:'center', headerName:'Type Paiement' , width: 150 },
        { field: 'tempsTravail', headerAlign: 'center',align:'center', headerName:'Temps travail' , width: 150 },
        {field: 'superficie', headerAlign: 'center',align:'center', headerName:'Superficie en m2' , width: 150},
        {field: 'pourcentageTaille', headerAlign: 'center',align:'center', headerName:'Taille réalisée' , width: 100},
        { field: 'utilisationEngrais', headerAlign: 'center',align:'center', headerName:'Utilisation Engrais', width: 150 },
        {field: 'periodeutilisationEngrais', headerAlign: 'center',align:'center', headerName:'Periode Utilisation Engrais' , width: 200},
        {field: 'quantiteEngrais', headerAlign: 'center',align:'center', headerName:'Quantite Engrais' , width: 100},
        { field: 'quantiteMainOeuvre', headerAlign: 'center',align:'center', headerName:'Quantite Main d\'oeuvre:', width: 150 },
        { field: 'traitementPesticide', headerAlign: 'center',align:'center', headerName:'Traitement pesticides', width: 150 },
        { field: 'fournisseurPesticide', headerAlign: 'center',align:'center', headerName: 'Fournisseur pesticides', width: 150 },
        { field: 'description', headerAlign: 'center',align:'center', headerName:'Description' , width: 150 },
    ]
    const [filterCode, setFilterCode] = useState(''); // Filter nom state
    const [filterPlanteur, setFilterPlanteur] = useState(''); // Filter prenom state
    const [filterPesticides, setFilterPesticides] = useState(''); // Filter genre state
    const [filterEngrais, setFilterEngrais] = useState(''); // Filter age state
    const [searchTerm, setSearchTerm] = useState('');
    const [plantation, setplantation]=useState([]);
    const [geoLib, setGeoLib] = useState(null);
    const [page, setPage]=useState(0)
    const { plantations, isFetching  } =  useSelector((state) => state.plantation);
    // const [paginationModel, setPaginationModel] = useState({
    //     pageSize: 20,
    //     page: 0,
    // });    
    
    const dispatch = useDispatch();

    const handleSearch = () => {
      const filters = {
        code: filterCode,
        pesticides: filterPesticides,
        engrais: filterEngrais,
        idPlanteur: filterPlanteur,
        
      };
            dispatch(plantationActions.filterPlantations(filters));
          };


    useEffect(()=>{
      const getGeolibrary = async () => {
            const { spherical } = await google.maps.importLibrary("geometry");
            return spherical;
        }

      getGeolibrary().then(spherical => setGeoLib(spherical)).catch(console.error);
      dispatch(dataActions.loadData());
    },[]);

    useEffect(() => {
      dispatch(plantationActions.page(page));
    }, [page]); 

    const showButton = plantation.length == 20;
    const showButton1 = page > 0;
    let plantationsCopie =[]
   if(plantations.length != 0){

     plantations.map(p=>{

       let area = {superficie:0};
       let plantationCopy = {...p,...area}
      
       let parsedContour = JSON.parse(p.contour);
       if(parsedContour.length>3){

            let areaCalculatorArray = [];
            parsedContour.map(point => {
               
                let areaPoint = {

                    lat: point.latitude,
                    lng: point.longitude
                };
                areaCalculatorArray.push(areaPoint);
                
            });

            
            if (geoLib) {
                let superficie = geoLib.computeArea(areaCalculatorArray);
                plantationCopy.superficie = superficie;
                console.log(superficie);
                
            }
            else {
              plantationCopy.superficie="Non calculée";
                console.log('cannot calculate rn ');
            }

            

            }
            else {
              plantationCopy.superficie="Non definie"
            }

            plantationsCopie.push(plantationCopy);

       
     })

   }


    return (
        <>
            {isFetching &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>}

            <BaseLayout breadcrumb={[{ label: "Acceuil" }, { label: t('menu.dashboard') }]}>

            <Card>
                <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <CardActions  style={{ justifyContent: "space-between" }}>
                <MKButton
                        variant="contained"
                        color="warning"
                        style={{ width: "50%" }}
                        onClick = {()=> dispatch(plantationActions.downloadExcel())}
                      >
                        {t("actions.download")+" Excel"}
                </MKButton>
                </CardActions>
                </Grid>
                </Grid>
                </Card>

            <Paper component="CounterCard" elevation={3} sx={{ padding: '16px', backgroundColor: '#f5f5f5', border: '1px solid #ddd' }}>
             <APIProvider apiKey={'AIzaSyAmZOaeihx0QrQKdyj9eudlCn9LqY54CU0'}>
             </APIProvider>
              <Grid container spacing={2} >
              <Grid item xs={12} md={12}>
                <DefaultCounterCard count={totalPlantations}
                                separator=","
                                title={t('dashboard.bloc2')}
                                description={`${totalA} Certification A ${totalB} Certification B`} />
              </Grid>
              </Grid>
            </Paper>

            <Paper component="form" elevation={3} sx={{ padding: '16px', backgroundColor: '#f5f5f5', border: '1px solid #ddd' }}>
      <SearchBarPlantation />
    </Paper>

                {(!plantationsCopie || plantationsCopie?.length == 0) ? <div>{t('messages.nodata')}</div> :
                    <>                          

                        <div style={{ height: 700, width: '110%' ,alignItems:'center' }}>
                            <StyledDataGrid
                                rows={plantationsCopie}
                                columns={columns}
                                getRowId={(r) => {
                                    if (r === null) { return null }
                                    return r.id
                                }}
                                sx={{
                                  borderColor: '#000000',
                                  boxShadow: 10,                                  
                                }}
                                // paginationModel={page}
                                // onPaginationModelChange={setPage}
                            /> 
                           {showButton1 && <Button color="primary" onClick={() => { setPage(page -1);console.log('pagea',page); }}>
                           page precédente
                            </Button>}
                           {showButton && <Button color="primary" onClick={() => { setPage(page +1);console.log('pagea',page); }}>
                           page suivante
                            </Button>}
                        </div>
                    </>}

            </BaseLayout>

        </>
    )
}

const SearchBarPlantation = () => {
  const [filterCode, setFilterCode] = useState('');
  const [filterUtilisationEngrais, setFilterUtilisationEngrais] = useState('');
  const [filterTraitementPesticide, setFilterTraitementPesticide] = useState('');
  const dispatch = useDispatch();

  const handleSearch = () => {
    const filters = {
      codePlantation: filterCode,
      utilisationEngrais: filterUtilisationEngrais,
      traitementPesticide: filterTraitementPesticide,
    };

    const dto = {
      criteria: filterObject(filters),
      page: 0,
      size: 200,
    };
    dispatch(plantationActions.search(dto));
  };

  const filterObject = (obj) => {
    const filtered = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        filtered[key] = obj[key];
      }
    });
    return filtered;
  };

  return (
    

        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
          <Grid item xs={12} md={3}>
            <TextField fullWidth variant="standard" label="Code Plantation" value={filterCode} onChange={(e) => setFilterCode(e.target.value)} />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth sx={{height:'100%'}}>
          <InputLabel id="Engrais" sx={{ color: '#888' }}>
                    Engrais
          </InputLabel>
            <Select
               labelId="Engrais"
               value={filterUtilisationEngrais}
               onChange={(e) => setFilterUtilisationEngrais(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white', height:'100%' }}
            >
                <MenuItem value={"OUI"}>Oui</MenuItem>
                <MenuItem value={"NON"}>Non</MenuItem>
                <MenuItem value={""}>Non Specifie</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth sx={{height:'100%'}}>
          <InputLabel id="Pesticides" sx={{ color: '#888' }}>
                      Traitement Pesticides
          </InputLabel>
            <Select
               labelId="Pesticides"
               value={filterTraitementPesticide}
               onChange={(e) => setFilterTraitementPesticide(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white', height:'100%' }}
            >
                <MenuItem value={"OUI"}>Oui</MenuItem>
                <MenuItem value={"NON"}>Non</MenuItem>
                <MenuItem value={""}>Non Specifie</MenuItem>

            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button fullWidth variant="contained" onClick={handleSearch} color="info" size="large">
              Lancer la recherche
            </Button>
          </Grid>
        </Grid>
  
  );
};

export default Plantation;

/* Long id;
    @ManyToOne(fetch= FetchType.LAZY)
    @JoinColumn(name = "PLANTEUR_ID")
    Planteur planteur;
    @ManyToOne
    @JoinColumn(name = "COOPERATIVE_ID")
    Cooperative cooperative;
    @ManyToOne
    @JoinColumn(name = "USER_ID")
    User user;
    String codePlantation;
    String description;
    String section;
    int plantationAge;
    int anneeCreation;
    @Enumerated(EnumType.STRING)
    UtilisationMainOeuvre utilisationMainOeuvre;
    @Enumerated(EnumType.STRING)
    TypePaiement typePaiement;
    @Enumerated(EnumType.STRING)
    MoyenPaiement moyenPaiement;
    @Enumerated(EnumType.STRING)
    TempsTravail tempsTravail;
    @Enumerated(EnumType.STRING)
    OrigineMainOeuvre origineMainOeuvre;
    @Enumerated(EnumType.STRING)
    UtilisationEngrais utilisationEngrais;
    String autreOrganisationAchat;
    @Enumerated(EnumType.STRING)
    TraitementPesticide traitementPesticide;
    @Enumerated(EnumType.STRING)
    FournisseurPesticide fournisseurPesticide;
    String typePesticide;
    String autreTypePesticide;
    String frequenceUtilisation;
    String autreFrequenceUtilisation;
    String periodeTraitement;
    String codePlantationBCS;
    @Column(length=1000)
    String contour;
    @Enumerated(EnumType.STRING)
    QuantiteMainOeuvre quantiteMainOeuvre;
    String typePlante;*/