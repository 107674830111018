import instance from "./AxiosInterceptor";

export const create = async (data) => {
    try {
        const response = await instance.post(`/section/createS`, data);
        console.log('Response:', response.data); // Log the response data
        return response.data; // Return the response data if needed
    } catch (error) {
        console.error('Error creating cooperative:', error);
        throw error; // Throw the error to handle it in the caller function
    }
};

export const list = () => {
    return instance.get(`/section/list`);
};

export const update = (id, data) => {
    return instance.put(`/section/updateS/${id}`, data);
};

export const deleteSection = (id) => {
    return instance.delete(`/section/deleteS/${id}`);
};

export const get = async (id) => {
    try {
       return await instance.get(`/section/${id}`);  
    } catch (error) {
        console.error('Error fetching cooperative:', error);
        throw error; 
    }
};

export default { create, list, update, deleteSection, get };