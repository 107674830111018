export const NOT_FOUND_ERROR_PAGE_ROUTE = "/page-introuvable"
export const SIGN_IN_PAGE = "/authentication/sign-in";
export const USER_MANAGE_PAGE = "/main/user";
export const PLANTATION_MANAGE_PAGE = "/main/plantation";
export const DASHBOARD_PAGE = "/main/dashboard";
export const MAIN_PAGE = "/main";
export const WELCOME_PAGE = "/welcome";
export const GEO_MANAGE_PAGE = "/main/geolocalisation";
export const PLANTEUR_MANAGE_PAGE = "/main/planteur";
export const COUCHE_VIEW_EDIT_FORM = "/main/couche/:operation/:id";
export const COUCHE_MANAGE_PAGE = "/main/couche";
export const COUCHE_FORM = "/main/couche/create";
export const COOP_MANAGE_PAGE = "/main/cooperative";
export const COOP_FORM = "/main/cooperative/create";
export const COOP_VIEW_EDIT_FORM = "/main/cooperative/:operation/:id";
export const SECTION_VIEW_EDIT_FORM = "/main/cooperative/section/:cooperativeId/:operation/:id";
export const SECTION_FORM = "/main/cooperative/section/create/:cooperativeId";