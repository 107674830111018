import React, { useEffect, useState, useCallback } from "react";
import { Backdrop, CircularProgress, Container, Grid, FormControl, TextField, InputLabel, MenuItem, Select, Paper, Button, Dialog, DialogContent, DialogTitle, Typography, Card, Slide } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "sagas/dataSlice";
import { planteurActions } from "sagas/planteurSlice";
import { plantationActions } from "sagas/plantationSlice";
import { cooperativeActions } from "sagas/cooperativeSlice";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import BaseLayout from "layouts/sections/components/BaseLayout";
import PlantationSearchResult from "./PlantationSearchResult";
import PlanteurSearchResult from "./PlanteurSearchResult";
import CoopResults from "./CoopResults";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useTranslation } from "react-i18next";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Dashboard = () => {
  const { t } = useTranslation('translation');
  const [openPopUp, setPopUp] = useState(false);
  const [search, setSearch] = useState(0);
  const [coopFilter,setCoopFilter] = useState(0);
  const { totalUsers, totalPlanteurs, totalPMales, totalPFemales, totalPlantations, plantationTypes, totalA, totalB, totalCoops, totalNbrEnfants, totalNbrEnfantsRisque, totalNbrEnfantsTravail, totalNbrEnfantsActeNaissance, totalNbrEnfantsNonScolarises, isFetching } = useSelector(state => state.data);
  const dispatch = useDispatch();
  const typeA = totalA;
  const typeB = totalB;
  const sansActeNaissance = totalNbrEnfants - totalNbrEnfantsActeNaissance;

  

    useEffect(() => {
      if(coopFilter!=0 && coopFilter != null && coopFilter !=undefined){
        dispatch(dataActions.coop(coopFilter));
      }
      else{
        dispatch(dataActions.loadData());
      }
  }, [coopFilter]);

  const handleSearchChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const N = plantationTypes?.NoixDeCajou || 0;
  const C = plantationTypes?.Cacao || 0;
  const B = plantationTypes?.Banane || 0;
  const M = plantationTypes?.Mais || 0;

  const data = [
    { name: 'Noix de Cajou', value: N },
    { name: 'Cacao', value: C },
    { name: 'Banane', value: B },
    { name: 'Mais', value: M },
  ];

  return (
    <>
      {isFetching && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <BaseLayout breadcrumb={[{ label: "Acceuil" }, { label: t('menu.dashboard') }]}>
        <br />
        <br />
        <br />
        <ReturnSearch search={search} setOpen={(v) => setPopUp(v)} />

        <Container sx={{ mt: 4 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sx={{ height: '60px' }} md={12}>
              <FormControl fullWidth sx={{ height: '100%' }}>
                <InputLabel id="search-label" sx={{ color: '#888' }}>Options Recherche</InputLabel>
                <Select
                  labelId="search-label"
                  value={search}
                  onChange={handleSearchChange}
                  variant="outlined"
                  fullWidth
                  sx={{ borderRadius: '4px', backgroundColor: 'white', height: '100%' }}
                >
                  <MenuItem value={1}>Cooperative</MenuItem>
                  <MenuItem value={2}>Planteur</MenuItem>
                  <MenuItem value={3}>Plantation</MenuItem>
                  <MenuItem value={0}>Cacher menu </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <DefaultCounterCard count={totalCoops} separator="," title="Nombre de Coopératives" />
            </Grid>

            <Grid item xs={12} md={4}>
              <DefaultCounterCard count={totalUsers} separator="," title={t('dashboard.bloc1')} />
            </Grid>
            <Grid item xs={12} md={4}>
              <DefaultCounterCard count={totalPlantations} separator="," title={t('dashboard.bloc2')} description={`${typeA} Certification A ${typeB} Certification B`} />
            </Grid>
            <Grid item xs={12} md={4}>
            </Grid>
            <Grid item xs={12} md={4}>
              <DefaultCounterCard count={totalPlanteurs} separator="," title={t('dashboard.bloc3')} description={`${totalPMales} Hommes ${totalPFemales} Femmes`} />
            </Grid>
            <Grid item xs={12} md={12}>
              <DefaultCounterCard count={totalNbrEnfants} separator="," title={`Nombre d'enfants en charge planteurs`} description={`${totalNbrEnfantsRisque} Enfants en risque de travail ${totalNbrEnfantsTravail} En situation de travail ${totalNbrEnfantsNonScolarises} Non scolarises ${sansActeNaissance} Sans Acte Naissance`} color="info" />
            </Grid>
            <Grid item xs={12} md={12}>
              <Card>
                <MKBox variant="gradient" bgColor="light" borderRadius="lg" coloredShadow="info" textAlign="center" sx={{ p: 5 }}>
                  <MKTypography variant="h5" color="text">
                    {t('dashboard.bloc4')}
                  </MKTypography>
                  <ResponsiveContainer aspect={2}>
                    <PieChart width={400} height={400}>
                      <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={240}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend wrapperStyle={{ fontSize: "14px" }} iconType="line" verticalAlign="bottom" align="center" />
                    </PieChart>
                  </ResponsiveContainer>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </BaseLayout>
      <Popup open={openPopUp} handleClose={() => setPopUp(false)} search={search} setCoopFilter={setCoopFilter} />
    </>
  );
};

export default Dashboard;

const SearchBarPlantation = ({ setOpen }) => {
  const [filterCode, setFilterCode] = useState('');
  const [filterUtilisationEngrais, setFilterUtilisationEngrais] = useState('');
  const [filterTraitementPesticide, setFilterTraitementPesticide] = useState('');
  const dispatch = useDispatch();

  const handleSearch = () => {
    const filters = {
      codePlantation: filterCode,
      utilisationEngrais: filterUtilisationEngrais,
      traitementPesticide: filterTraitementPesticide,
    };

    const dto = {
      criteria: filterObject(filters),
      page: 0,
      size: 200,
    };
    dispatch(plantationActions.search(dto));
    setOpen(true);
  };

  const filterObject = (obj) => {
    const filtered = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        filtered[key] = obj[key];
      }
    });
    return filtered;
  };

  return (
    <MKBox component="section" my={1} py={1}>
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
          <Grid item xs={12} md={3}>
            <TextField fullWidth variant="standard" label="Code Plantation" value={filterCode} onChange={(e) => setFilterCode(e.target.value)} />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth sx={{height:'100%'}}>
          <InputLabel id="Engrais" sx={{ color: '#888' }}>
                    Engrais
          </InputLabel>
            <Select
               labelId="Engrais"
               value={filterUtilisationEngrais}
               onChange={(e) => setFilterUtilisationEngrais(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white', height:'100%' }}
            >
                <MenuItem value={"OUI"}>Oui</MenuItem>
                <MenuItem value={"NON"}>Non</MenuItem>
                <MenuItem value={""}>Non Specifie</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth sx={{height:'100%'}}>
          <InputLabel id="Pesticides" sx={{ color: '#888' }}>
                      Traitement Pesticides
          </InputLabel>
            <Select
               labelId="Pesticides"
               value={filterTraitementPesticide}
               onChange={(e) => setFilterTraitementPesticide(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white', height:'100%' }}
            >
                <MenuItem value={"OUI"}>Oui</MenuItem>
                <MenuItem value={"NON"}>Non</MenuItem>
                <MenuItem value={""}>Non Specifie</MenuItem>

            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button fullWidth variant="contained" onClick={handleSearch} color="info" size="large">
              Lancer la recherche
            </Button>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

const SearchBarPlanteur = ({ setOpen }) => {

  const [filterNom, setFilterNom] = useState(''); // Filter nom state
  const [filterPrenom, setFilterPrenom] = useState(''); // Filter prenom state
  const [filterGenre, setFilterGenre] = useState(''); // Filter genre state
  const [filterAge, setFilterAge] = useState(''); // Filter age state

  const dispatch = useDispatch();

  const handleSearch = () => {
    const filters = {
      nom: filterNom,
      age: filterAge,
      prenom: filterPrenom,
      genre: filterGenre

    };

    const dto = {
      criteria: filterObject(filters),
      page: 0,
      size: 200,
    };
    dispatch(planteurActions.searchPlanteurs(dto));
    setOpen(true);
  };

  const filterObject = (obj) => {
    const filtered = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        filtered[key] = obj[key];
      }
    });
    return filtered;
  };

  return (
    <MKBox component="section" my={1} py={1}>
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
          
          <Grid item xs={12} md={2}>
            <TextField fullWidth variant="standard" label="Nom" value={filterNom} onChange={(e) => setFilterNom(e.target.value)} />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField fullWidth variant="standard" label="Prenom" value={filterPrenom} onChange={(e) => setFilterPrenom(e.target.value)} />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
          <FormControl fullWidth sx={{height:'100%'}}>
            <InputLabel id="genre" sx={{ color: '#888' }}>
                        Genre
            </InputLabel>
            <Select
               labelId="genre"
               value={filterGenre}
               onChange={(e) => setFilterGenre(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white', height:'100%' }}
            >
             <MenuItem value={"MALE"}>Homme</MenuItem>
             <MenuItem value={"FEMALE"}>Femme</MenuItem>
             <MenuItem value={""}>Non Specifie</MenuItem>

            </Select>
          </FormControl>
        </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth sx={{height:'100%'}} >
            <InputLabel id="age" sx={{ color: '#888' }}>
                              Age
            </InputLabel>
            <Select
               labelId="Age"
               value={filterAge}
               onChange={(e) => setFilterAge(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ borderRadius: '4px', backgroundColor: 'white', height:'100%' }}
            >
            <MenuItem value={"18_25"}>18 - 25</MenuItem>
             <MenuItem value={"25_35"}>25 - 35</MenuItem>
             <MenuItem value={"35_45"}>35 - 45</MenuItem>
             <MenuItem value={"45_55"}>45 - 55</MenuItem>
             <MenuItem value={"55_65"}>55 - 65</MenuItem>
             <MenuItem value={"65_75"}>65 - 75</MenuItem>
             <MenuItem value={"75_85"}>75 - 85</MenuItem>
             <MenuItem value={""}>Non Specifie</MenuItem>
            </Select>
          </FormControl>
          </Grid>
         
          <Grid item xs={12} md={4}>
            <Button fullWidth variant="contained" onClick={handleSearch} color="info" size="large">
              Lancer la recherche
            </Button>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

const SearchBarCoop = ({ setOpen }) => {
  const [filterEmail, setFilterEmail] = useState('');
  const [filterNom, setFilterNom] = useState('');

  const dispatch = useDispatch();

  const handleSearch = () => {
    const filters = {
      nom: filterNom,
      email: filterEmail
    };

    const dto = {
      criteria: filterObject(filters),
      page: 0,
      size: 200,
    };
    dispatch(cooperativeActions.search(dto));
    setOpen(true);
  };

  const filterObject = (obj) => {
    const filtered = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        filtered[key] = obj[key];
      }
    });
    return filtered;
  };

  return (
    <MKBox component="section" my={1} py={1}>
      <Container>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
         
          <Grid item xs={12} md={3}>
            <TextField fullWidth variant="standard" label="Nom Coopérative" value={filterNom} onChange={(e) => setFilterNom(e.target.value)} />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField fullWidth variant="standard" label="Email" value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} />
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Button fullWidth variant="contained" onClick={handleSearch} color="info" size="large">
              Lancer la recherche
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button fullWidth variant="contained" onClick={()=>{setCoopFilter(0)}} color="info" size="large">
              Reinisialiser
            </Button>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};


const Popup = ({ open, handleClose, search, setCoopFilter}) => {
  const [openDetails, setOpenDetails] = useState(false);
  const { planteurs, plantations, cooperatives, isFetching } = useSelector(state => state.data);

  const searchComponents = {
    1: <CoopResults  handleClose={handleClose} />,
    2: <PlanteurSearchResult planteurs={planteurs} />,
    3: <PlantationSearchResult plantations={plantations} />,
  };

  return (
    <Dialog PaperProps={{
        style: {
          width: '80%',
          height: '80%',
          margin: 'auto',
        },
      }} open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Resultas</Typography>
          <Button onClick={handleClose} color="inherit">Fermer</Button>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isFetching && (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {searchComponents[search]}
      </DialogContent>
    </Dialog>
  );
};

const ReturnSearch = ({ search, setOpen }) => {
  if (search === 1) return <SearchBarCoop setOpen={setOpen} />;
  if (search === 2) return <SearchBarPlanteur setOpen={setOpen} />;
  if (search === 3) return <SearchBarPlantation setOpen={setOpen} />;
  return null;
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);